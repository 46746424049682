import { apiClient } from 'modules/app/configs/axios';

import { TechnicianData } from '../interfaces';

const endpoint = '/company/technicians';

export const getAllTechnicians = async () => {
  try {
    const response = await apiClient.get(`${endpoint}`);

    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const createTechnician = async (data: TechnicianData) => {
  try {
    const response = await apiClient.post(`${endpoint}`, data);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const updateTechnician = async ({ id, ...data }: TechnicianData) => {
  try {
    const response = await apiClient.put(`${endpoint}/${id}`, data);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const getOneTechnician = async (id?: number) => {
  try {
    const response = await apiClient.get(`${endpoint}/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const deleteAdminTechnician = async (id: number) => {
  try {
    const response = await apiClient.delete(`${endpoint}/${id}`);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};
