import { useMemo, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GridColDef } from '@mui/x-data-grid';
import { IconButton, Stack } from '@mui/material';
import { EditOutlined, DeleteOutlined } from '@mui/icons-material';

import { Table, useTableState } from 'modules/ui/table';
import { Loader } from 'modules/ui/loader';
import { useConfirmation, useModal } from 'modules/ui/modal';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { changeCurrency } from 'modules/financial/utils';
import { secToMin } from 'modules/call/utils';

import { deleteAdminTechnician, getAllTechnicians } from '../hooks/crud';
import { TechnicianData } from '../interfaces';
import { TechnicianFormModal } from './TechnicianFormModal';

export const TechnicianList = () => {
  const { t } = useTranslation('common');
  const [userId, setUserId] = useState<number>();
  const queryClient = useQueryClient();
  const { show } = useMessages();
  const { toDollar } = changeCurrency();
  const { isOpen, close, showModal } = useModal();
  const [tableState, onChangeTableState] = useTableState();

  const {
    data: technicians,
    isError,
    isLoading,
    error,
  } = useQuery<TechnicianData[]>(['allTechnician'], () => getAllTechnicians());

  useEffect(() => {
    if (isError && error) {
      show({ message: `${t('general.messages.failed')}. ${error}` });
    }
  }, [error, isError, show, t]);

  const {
    mutate: deleteMutation,
    isSuccess: isSuccessDelete,
    isLoading: isLoadingDelete,
  } = useMutation(deleteAdminTechnician, {
    onSuccess: () => {
      show({ message: t('general.messages.success'), severity: 'success' });

      queryClient.invalidateQueries(['allTechnician']);
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const [confirm] = useConfirmation({
    title: t('general.modal.title.delete'),
    submitButtonText: t('general.button.delete'),
    isLoading: isLoadingDelete,
  });

  const handleEdit = useCallback(
    (id: number) => () => {
      setUserId(id);
      showModal();
    },
    [showModal],
  );

  const handleConfirmDelete = useCallback(
    (id: number, name: string) => () =>
      confirm({
        onSubmit: close => {
          deleteMutation(id);

          if (isSuccessDelete) {
            close();
          }
        },
        description: t('technician.confirmation.description', {
          name,
        }),
      }),
    [confirm, deleteMutation, isSuccessDelete, t],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'full_name',
        headerName: `${t('technician.list.technicianName')}`,
        flex: 1,
        minWidth: 165,
      },
      {
        field: 'phone_number',
        headerName: `${t('technician.list.technicianPhone')}`,
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'base_call_price',
        headerName: `${t('technician.list.pricePerCall')}`,
        valueGetter: ({ value }) => (value ? `$${toDollar(value)}` : '-'),
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'stats.total_calls',
        headerName: `${t('technician.list.totalCalls')}`,
        valueGetter: params => params.row.stats.total_calls || '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'stats.monthly_calls',
        headerName: `${t('technician.list.callThisMonth')}`,
        valueGetter: params => params.row.stats.monthly_calls || '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'stats.total_fees',
        headerName: `${t('technician.list.feesTotal')}`,
        valueGetter: params =>
          params.row.stats.total_fees ? `$${toDollar(params.row.stats.total_fees)}` : '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'stats.monthly_fees',
        headerName: `${t('technician.list.feesMonth')}`,
        valueGetter: params =>
          params.row.stats.monthly_fees ? `$${toDollar(params.row.stats.monthly_fees)}` : '-',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'stats.monthly_avg_call_duration',
        headerName: `${t('technician.list.callAvgDuration')}`,
        valueGetter: params =>
          params.row.stats.monthly_avg_call_duration
            ? `${secToMin(params.row.stats.monthly_avg_call_duration)} mins`
            : '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'actions',
        headerName: `${t('general.list.actions')}`,
        headerAlign: 'right',
        align: 'right',
        sortable: false,
        disableColumnMenu: true,
        renderCell: params => (
          <Stack direction="row" useFlexGap gap={1}>
            <IconButton onClick={handleEdit(params.row.id)} color="info">
              <EditOutlined sx={{ fontSize: 18 }} />
            </IconButton>
            <IconButton
              color="error"
              onClick={handleConfirmDelete(params.row.id, params.row.full_name)}>
              <DeleteOutlined sx={{ fontSize: 18 }} />
            </IconButton>
          </Stack>
        ),
        width: 130,
        minWidth: 130,
      },
    ],
    [handleConfirmDelete, handleEdit, t, toDollar],
  );

  return (
    <>
      <Loader isLoading={isLoading} />

      <Table
        loading={isLoading}
        columns={columns}
        rows={technicians || []}
        rowCount={technicians ? technicians.length : 0}
        state={tableState}
        onChangeState={onChangeTableState}
      />

      <TechnicianFormModal
        open={isOpen}
        onClose={close}
        userId={userId}
        title={t('technician.edit.title')}
      />
    </>
  );
};
