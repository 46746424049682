import { useTranslation } from 'react-i18next';

import { Modal, ModalProps } from 'modules/ui/modal';

import { CreateCall } from './CreateCall';

interface Props extends ModalProps {}

export const CreateCallModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Modal maxWidth="sm" open={open} onClose={onClose} title={t('call.create.title')}>
      <CreateCall onModalClose={onClose} />
    </Modal>
  );
};
