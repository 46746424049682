import { apiClient } from 'modules/app/configs/axios';
import { Token } from 'modules/auth/types';

import { AuthData, AuthTechnicianData } from '../interfaces';

const endpoint = '/auth';

export const adminRegister = async (data: AuthData) => {
  try {
    const response = await apiClient.post(`${endpoint}/register`, data);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const verifyEmail = async (token: Token) => {
  try {
    const response = await apiClient.get(`${endpoint}/verify_email?token=${token}`);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const resetEmail = async (data: Omit<AuthData, 'password'>) => {
  const response = await apiClient.post(`${endpoint}/send_password_reset_email`, data);
  return response.data;
};

export const createNewPassword = async (token: Token, new_password: string) => {
  try {
    const response = await apiClient.post(`${endpoint}/reset_password`, { token, new_password });
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const adminLogin = async (email: string, password: string) => {
  try {
    return await apiClient.post(`${endpoint}/login/admin`, {
      email,
      password,
    });
  } catch (error: any) {
    throw error.data;
  }
};

export const verifyPhone = async (data: Omit<AuthTechnicianData, 'verification_code'>) => {
  try {
    const response = await apiClient.post(`${endpoint}/send_phone_verification_code`, data);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const technicianLogin = async (data: AuthTechnicianData) => {
  try {
    return await apiClient.post(`${endpoint}/login/technician`, data);
  } catch (error: any) {
    throw error.data;
  }
};
