import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ModalProvider } from 'react-modal-hook';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'modules/language/configs/i18n';
import { theme } from 'modules/app/configs/theme';
import { AuthProvider } from 'modules/auth/components/AuthProvider';

import reportWebVitals from './reportWebVitals';
import { App } from './pages/_app';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ModalProvider>
          <BrowserRouter>
            <CssBaseline />
            <App />
            <ToastContainer />
          </BrowserRouter>
        </ModalProvider>
      </AuthProvider>
    </ThemeProvider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
