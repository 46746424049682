import { apiClient } from 'modules/app/configs/axios';

const endpoint = '/dashboards';

export const getCAStats = async () => {
  try {
    const response = await apiClient.get(`${endpoint}/company_admin_stats`);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};
