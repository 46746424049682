import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Box, Stack } from '@mui/material';

import { Text, Phone } from 'modules/ui/forms';
import { isPhoneNumber } from 'modules/ui/forms/validation/isPhoneNumber';
import { useMessages } from 'modules/messages/hooks/useMessages';

import { verifyPhone, technicianLogin } from '../../hooks/crud';
import { AuthTechnicianData } from '../../interfaces';
import { useAuth } from '../../hooks/useAuth';

export const SignInTechnician = () => {
  const { t } = useTranslation('common');
  const { setToken } = useAuth();
  const [verCodeSend, setVerCodeSend] = useState(false);
  const { show } = useMessages();

  const schema = useMemo(
    () =>
      yup.object({
        phone_number: yup
          .string()
          .required(t('general.validation.required'))
          .test('isValidPhone', t('general.validation.phone'), isPhoneNumber),
        ...(verCodeSend
          ? {
              verification_code: yup.string().required(t('general.validation.required')),
            }
          : {}),
      }),
    [verCodeSend, t],
  );

  const { control, handleSubmit, reset } = useForm<AuthTechnicianData>({
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const { mutate: verifyPhoneMutation } = useMutation(verifyPhone, {
    onSuccess: () => {
      show({ message: t('general.messages.auth.verifyPhoneSuccess'), severity: 'success' });

      setVerCodeSend(true);
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const { mutate: loginMutation } = useMutation(technicianLogin, {
    onSuccess: response => {
      const newToken = response.headers['x-api-token'];

      setToken(newToken);

      show({ message: t('general.messages.auth.loginSuccess'), severity: 'success' });
      reset();
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const handleFormSubmit = useCallback(
    (data: AuthTechnicianData) => {
      if (verCodeSend) {
        loginMutation(data);
      } else {
        verifyPhoneMutation(data);
      }
    },
    [loginMutation, verCodeSend, verifyPhoneMutation],
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Stack spacing={3}>
        <Phone
          label={t('general.field.phone.label')}
          name="phone_number"
          control={control}
          required
        />
        {verCodeSend && (
          <Text
            label={t('general.field.verificationCode.label')}
            name="verification_code"
            control={control}
            required
          />
        )}
      </Stack>

      <Box mt={3}>
        <Button type="submit" fullWidth>
          {t('auth.button.signIn')}
        </Button>
      </Box>
    </form>
  );
};
