import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Button } from '@mui/material';

import { LightLayout } from 'modules/layouts/components/LightLayout';

export const NotFoundPage = () => {
  const { t } = useTranslation('common');

  return (
    <LightLayout>
      <Stack spacing={3} alignItems="center" m="auto">
        <Typography fontSize={120}>{t('system.errors.404.title')}</Typography>
        <Typography variant="h1" gutterBottom>
          {t('system.errors.404.message')}
        </Typography>
        <Button component={Link} to="/">
          {t('system.button.homepage')}
        </Button>
      </Stack>
    </LightLayout>
  );
};
