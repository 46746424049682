export enum StripeStatusEnum {
  Draft = 'draft',
  Deleted = 'deleted',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}

export enum SessionStatusEnum {
  Complete = 'complete',
  Expired = 'expired',
  Open = 'open',
}

export enum PaymentStatusEnum {
  Paid = 'paid',
  Unpaid = 'unpaid',
}
export enum FeeStatusEnum {
  Pending = 'pending',
  Sent = 'sent',
}

export interface FinancialData {
  id?: number;
  date: string;
  company_name: string;
  call_count: number;
  call_fees: string | number;
  service_fees: string | number;
  net_proceeds: string | number;
  status?: FeeStatusEnum;
  is_sent: boolean;
}

export interface SessionInterface {
  payment_status: PaymentStatusEnum;
  status: SessionStatusEnum;
}

export interface SentDataInterface {
  reportId: number;
  isSent: boolean;
}
