import { Route, Switch, Redirect } from 'react-router-dom';

import { AuthLayout } from 'modules/layouts';

import { SignInPage } from './Auth/SignIn';
import { SignUpPage } from './Auth/SignUp';
import { PasswordResetPage } from './Auth/PasswordReset';
import { PasswordCreatePage } from './Auth/PasswordCreate';
import { VerifyEmailPage } from './Auth/VerifyEmail';

export const Public = () => {
  return (
    <AuthLayout>
      <Switch>
        <Route exact path="/sign-in" component={SignInPage} />
        <Route exact path="/sign-up" component={SignUpPage} />
        <Route exact path="/password-reset" component={PasswordResetPage} />
        <Route exact path="/password-create" component={PasswordCreatePage} />
        <Route exact path="/verify-email" component={VerifyEmailPage} />
        <Redirect to="/sign-in" />
      </Switch>
    </AuthLayout>
  );
};
