import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { LightLayout } from 'modules/layouts/components/LightLayout';
import { Loader } from 'modules/ui/loader';
import { ResponseError } from 'modules/app/configs/axios';
import { DailyCallPrebuilt } from 'modules/call/components/DailyCallPrebuilt';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { technicianCallRoom } from 'modules/call/hooks/crud';
import { CallRoomInterface } from 'modules/call/interfaces';

export const CallTechnicianPage = () => {
  const { t } = useTranslation('common');
  const { show } = useMessages();
  const { callToken } = useParams<{ callToken: string }>();
  const [showSuccess, setShowSuccess] = useState(false);

  const { data, isLoading, isError, isSuccess, error } = useQuery<CallRoomInterface, ResponseError>(
    ['technicianCallRoom'],
    () => technicianCallRoom(callToken),
    {
      enabled: !!callToken && !showSuccess,
      retry: false,
    },
  );

  useEffect(() => {
    if (isSuccess) {
      setShowSuccess(true);
    }

    if (isError && error) {
      if (error?.statusCode === 400) {
        show({ message: t('call.messages.linkHasExpired') });
      } else {
        show({ message: `${t('general.messages.failed')}. ${error.data}` });
      }
    }
  }, [error, isError, isSuccess, show, t]);

  return (
    <LightLayout>
      <Loader isLoading={isLoading && !data} />
      {data && <DailyCallPrebuilt roomName={data.room_name} meetingToken={data.meeting_token} />}
    </LightLayout>
  );
};
