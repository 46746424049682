import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { Button, Box, Stack } from '@mui/material';

import { Password } from 'modules/ui/forms';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { isPasswordStrong } from 'modules/ui/forms/validation/isPasswordStrong';

import { createNewPassword } from '../../hooks/crud';

export const PasswordCreateForm = () => {
  const { t } = useTranslation('common');
  const { show } = useMessages();
  const history = useHistory();
  const { search } = useLocation();
  const passwordToken = new URLSearchParams(search).get('token');

  const schema = useMemo(
    () =>
      yup.object({
        new_password: yup
          .string()
          .trim()
          .test('test-pass', t('general.validation.passwordStrong') as string, value =>
            Boolean(value && isPasswordStrong.test(value)),
          )
          .required(t('general.validation.required')),
        passwordConfirm: yup
          .string()
          .test(
            'passwords-match',
            t('general.validation.passwordMatch') as string,
            function (value) {
              return this.parent.new_password === value;
            },
          ),
      }),
    [t],
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      new_password: '',
      passwordConfirm: '',
    },
    resolver: yupResolver(schema),
  });

  const { mutate } = useMutation(
    (data: any) => createNewPassword(passwordToken, data.new_password),
    {
      onSuccess: () => {
        history.push('/sign-in');
        reset();

        show({ message: t('general.messages.auth.resetPasswordSuccess'), severity: 'success' });
      },
      onError: ({ message }) => {
        show({ message: `${t('general.messages.failed')}. ${message}` });
      },
    },
  );

  const handleFormSubmit = useCallback((data: any) => mutate(data), [mutate]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Stack spacing={3}>
        <Password
          label={t('general.field.password.label')}
          name="new_password"
          type="password"
          control={control}
          required
        />
        <Password
          label={t('general.field.confirmPassword.label')}
          name="passwordConfirm"
          type="password"
          control={control}
          required
        />
      </Stack>

      <Box mt={3}>
        <Button type="submit" fullWidth>
          {t('general.button.save')}
        </Button>
      </Box>
    </form>
  );
};
