import { memo, useMemo } from 'react';
import { Controller, Control } from 'react-hook-form';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';

export interface PhoneProps {
  name: string;
  control: Control<any, any>;
  label?: MuiTelInputProps['label'];
  required?: MuiTelInputProps['required'];
  margin?: MuiTelInputProps['margin'];
  InputProps?: MuiTelInputProps['InputProps'];
  readOnly?: boolean;
}

export const Phone = memo(
  ({ name, control, label, required, margin, readOnly = false, InputProps }: PhoneProps) => {
    const InputPropsMemo = useMemo(() => ({ ...InputProps, readOnly }), [readOnly, InputProps]);

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { invalid, error } }) => (
          <MuiTelInput
            value={field.value ?? ''}
            inputRef={field.ref}
            onChange={value => {
              field.onChange(value);
            }}
            fullWidth
            disableDropdown
            forceCallingCode
            InputProps={InputPropsMemo}
            label={label}
            required={required}
            margin={margin}
            defaultCountry="US"
            langOfCountryName="en"
            variant="outlined"
            error={invalid}
            helperText={error?.message}
            sx={{
              '.MuiTelInput-Flag': { width: 24, height: 18, img: { width: 24, height: 18 } },
              '.MuiTypography-root': { color: 'common.black' },
            }}
          />
        )}
      />
    );
  },
);
