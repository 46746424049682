import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { LightLayout } from 'modules/layouts/components/LightLayout';
import { Loader } from 'modules/ui/loader';
import { Alert } from 'modules/ui/alert';
import { ResponseError } from 'modules/app/configs/axios';
import { DailyCallPrebuilt } from 'modules/call/components/DailyCallPrebuilt';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { clientCallRoom } from 'modules/call/hooks/crud';
import { CallRoomInterface } from 'modules/call/interfaces';

export const CallClientPage = () => {
  const { t } = useTranslation('common');
  const { show } = useMessages();
  const { search } = useLocation();
  const stripeSuccess = new URLSearchParams(search).get('success');
  const { callToken } = useParams<{ callToken: string }>();
  const [showSuccess, setShowSuccess] = useState(false);

  const { data, isLoading, isError, isSuccess, error } = useQuery<CallRoomInterface, ResponseError>(
    ['clientCallRoom'],
    () => clientCallRoom(callToken),
    {
      enabled: !!callToken && !showSuccess,
      retry: false,
      refetchInterval: 5000,
    },
  );

  useEffect(() => {
    if (isSuccess && stripeSuccess) {
      setShowSuccess(true);
    }

    if (isError && error) {
      if (error?.statusCode === 400) {
        show({ message: t('call.messages.linkHasExpired') });
      } else if (error?.statusCode === 402 && !!error?.data.url && !stripeSuccess) {
        window.location.replace(error.data.url);
      } else if (error?.statusCode !== 400 && error?.statusCode !== 402) {
        show({ message: `${t('general.messages.failed')}. ${error.data || ''}` });
      }
    }
  }, [error, isError, isSuccess, show, stripeSuccess, t]);

  return (
    <LightLayout>
      <Loader isLoading={isLoading && !data} />
      {data ? (
        <DailyCallPrebuilt roomName={data.room_name} meetingToken={data.meeting_token} />
      ) : (
        <Alert message={t('call.messages.stripeCheckout')} severity="info" />
      )}
    </LightLayout>
  );
};
