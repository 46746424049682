import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography, Box, Stack, Button } from '@mui/material';

import { Alert } from 'modules/ui/alert';
import { ProfileForm } from 'modules/profile/components/ProfileForm';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { useConfirmation } from 'modules/ui/modal';
import { deleteAdminTechnician } from 'modules/technician/hooks/crud';
import { useMessages } from 'modules/messages/hooks/useMessages';

export const ProfilePage = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { show } = useMessages();
  const { isUserEmptyProfile, isSuperAdmin, user, logout } = useAuth();

  const {
    mutate: deleteMutation,
    isSuccess: isSuccessDelete,
    isLoading: isLoadingDelete,
  } = useMutation(deleteAdminTechnician, {
    onSuccess: () => {
      show({ message: t('general.messages.success'), severity: 'success' });

      logout();
      queryClient.invalidateQueries(['allTechnician']);
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const [confirm] = useConfirmation({
    title: t('general.modal.title.delete'),
    submitButtonText: t('general.button.delete'),
    isLoading: isLoadingDelete,
  });

  const handleConfirmDelete = useCallback(
    () =>
      confirm({
        onSubmit: close => {
          if (user) {
            deleteMutation(user?.id);
          }

          if (isSuccessDelete) {
            close();
          }
        },
        description: t('profile.confirmation.description'),
      }),
    [confirm, deleteMutation, isSuccessDelete, t, user],
  );

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} mb={3}>
        <Typography variant="h1">{t('profile.title')}</Typography>
        {!isUserEmptyProfile && !isSuperAdmin && (
          <Button onClick={handleConfirmDelete} color="error">
            {t('general.button.delete')}
          </Button>
        )}
      </Stack>

      {isUserEmptyProfile && (
        <Box mb={3}>
          <Alert message={t('profile.message')} severity="info" />
        </Box>
      )}
      <ProfileForm />
    </>
  );
};
