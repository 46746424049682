import { apiClient } from 'modules/app/configs/axios';
import { Token } from 'modules/auth/types';
import { TableState, apiTableParams } from 'modules/ui/table';

import { CreateCallData, PeriodCallsEnum } from '../interfaces';
import { TODAY, LAST_WEEK } from '../constants';

const endpoint = '/calls';

export const createCall = async (data: CreateCallData) => {
  try {
    const response = await apiClient.post(`${endpoint}`, data);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const getServerStateAllCalls = async (tableState: TableState) => {
  try {
    const tableParams = apiTableParams(tableState);

    const response = await apiClient.get(`${endpoint}`, { params: tableParams });
    const pagination = JSON.parse(response.headers['x-pagination']);

    return { data: response.data, totalPages: pagination.total };
  } catch (error: any) {
    throw error.data;
  }
};

export const getAllCalls = async (periodCalls: PeriodCallsEnum) => {
  try {
    const response = await apiClient.get(`${endpoint}`, {
      params: {
        from_time: periodCalls === PeriodCallsEnum.Today ? TODAY : LAST_WEEK,
      },
    });
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const technicianCallRoom = async (token: Token) => {
  try {
    const response = await apiClient.post(`${endpoint}/technician_token`, { token });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const clientCallRoom = async (token: Token) => {
  try {
    const response = await apiClient.post(`${endpoint}/client_token`, { token });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const callCheckout = async (call_id: number) => {
  try {
    const response = await apiClient.post(`${endpoint}/${call_id}/checkout`);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const callRegenerate = async (call_id: number) => {
  try {
    const response = await apiClient.post(`${endpoint}/${call_id}/regenerate`);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};
