import { Route, Switch } from 'react-router-dom';

import { useAuth } from 'modules/auth/hooks/useAuth';

import { NotFoundPage } from './System/NotFoundPage';
import { PermissionDeniedPage } from './System/PermissionDeniedPage';
import { CardAuthorizeErrorPage } from './System/CardAuthorizeErrorPage';
import { SuccessCallPage } from './System/SuccessCallPage';
import { CallClientPage } from './Call/CallClient';
import { CallTechnicianPage } from './Call/CallTechnician';
import { Private } from './_private';
import { Public } from './_public';

export const App = () => {
  const { isSignedIn } = useAuth();

  return (
    <Switch>
      <Route exact path="/call/:callToken" component={CallClientPage} />
      <Route exact path="/room/:callToken" component={CallTechnicianPage} />
      <Route exact path="/403" component={PermissionDeniedPage} />
      <Route exact path="/payment-unaccepted" component={CardAuthorizeErrorPage} />
      <Route exact path="/successful-call" component={SuccessCallPage} />
      <Route path="/" component={isSignedIn ? Private : Public} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};
