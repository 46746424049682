import { ReactNode } from 'react';
import { alpha, Box, styled } from '@mui/material';
import { LabelColorType } from '../types';

const LabelStyled = styled(Box, { shouldForwardProp: prop => prop !== 'color' })<{
  color?: LabelColorType;
}>(({ color, theme }) => [
  {
    fontSize: 14,
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: 100,
  },
  color === 'grey' && {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[500],
  },
  color === 'info' && {
    backgroundColor: alpha(theme.palette.info.main, 0.15),
    color: theme.palette.info.main,
  },
  color === 'secondary' && {
    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
    color: theme.palette.secondary.main,
  },
  color === 'error' && {
    backgroundColor: alpha(theme.palette.error.main, 0.15),
    color: theme.palette.error.main,
  },
  color === 'success' && {
    backgroundColor: alpha(theme.palette.success.main, 0.15),
    color: theme.palette.success.main,
  },
  color === 'warning' && {
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
  },
  color === 'disabled' && {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.disabled,
  },
]);

export interface LabelProps {
  text: ReactNode;
  color?: LabelColorType;
}

export const Label = ({ text, color }: LabelProps) => (
  <LabelStyled color={color}>{text}</LabelStyled>
);
