import { useTranslation } from 'react-i18next';
import { Typography, Stack, Button } from '@mui/material';

import { useModal } from 'modules/ui/modal';
import { TechnicianFormModal } from 'modules/technician/conponents/TechnicianFormModal';
import { TechnicianList } from 'modules/technician/conponents/TechnicianList';

export const TechnicianPage = () => {
  const { t } = useTranslation('common');
  const { isOpen, close, showModal } = useModal();

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} mb={3}>
        <Typography variant="h1">{t('technician.title')}</Typography>
        <Button onClick={showModal}>{t('general.button.create')}</Button>
      </Stack>

      <TechnicianList />

      <TechnicianFormModal open={isOpen} onClose={close} />
    </>
  );
};
