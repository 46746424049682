import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { Button, Box, Stack } from '@mui/material';

import { Text, Password } from 'modules/ui/forms';
import { useMessages } from 'modules/messages/hooks/useMessages';

import { adminLogin } from '../../hooks/crud';
import { useAuth } from '../../hooks/useAuth';

interface FormValue {
  email: string;
  password: string;
}

export const SignInForm = () => {
  const { t } = useTranslation('common');
  const { setToken } = useAuth();
  const { show } = useMessages();

  const schema = useMemo(
    () =>
      yup.object({
        email: yup.string().email().required(t('general.validation.required')),
        password: yup.string().required(t('general.validation.required')),
      }),
    [t],
  );

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const loginMutation = useMutation((data: FormValue) => adminLogin(data.email, data.password), {
    onSuccess: response => {
      const newToken = response.headers['x-api-token'];

      setToken(newToken);

      show({ message: t('general.messages.auth.loginSuccess'), severity: 'success' });
    },
  });

  const handleFormSubmit = useCallback(
    async (data: FormValue) => {
      try {
        await loginMutation.mutateAsync(data);
      } catch (error: any) {
        show({
          message: `${t('general.messages.failed')}. ${error?.message || error?.data}`,
        });
      }
    },
    [loginMutation, show, t],
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Stack spacing={3}>
        <Text
          label={t('general.field.email.label')}
          name="email"
          type="email"
          control={control}
          required
        />
        <Password
          label={t('general.field.password.label')}
          name="password"
          type="password"
          control={control}
          required
        />
      </Stack>

      <Box mt={3}>
        <Button type="submit" fullWidth>
          {t('auth.button.signIn')}
        </Button>
      </Box>
    </form>
  );
};
