import { useTranslation } from 'react-i18next';
import { Paper, Typography, styled, alpha } from '@mui/material';

import { LightLayout } from 'modules/layouts/components/LightLayout';
import { useAuth } from '../../modules/auth/hooks/useAuth';

const PaperStyled = styled(Paper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.success.main, 0.075),
  margin: 'auto',
  padding: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(7),
  },
}));

export const SuccessCallPage = () => {
  const { t } = useTranslation('common');
  const { isTechnician } = useAuth();

  //TODO: Change to real data
  const callUserName = 'Jeff Braun';
  const technicianUserName = 'Zaki Taylor';

  return (
    <LightLayout>
      <PaperStyled>
        <Typography variant="h1" textAlign="center" mb={4}>
          {t('system.success.call.title')}
        </Typography>
        <Typography variant="h3" mb={3}>
          {t('system.success.call.thanks')}&nbsp;{isTechnician ? technicianUserName : callUserName}
        </Typography>
        {isTechnician ? (
          <Typography gutterBottom mb={2}>
            {t('system.success.call.message.technician')}
          </Typography>
        ) : (
          <Typography gutterBottom mb={2}>
            {t('system.success.call.message.customer')}
          </Typography>
        )}
      </PaperStyled>
    </LightLayout>
  );
};
