import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { Table, useTableState } from 'modules/ui/table';
import { Loader } from 'modules/ui/loader';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { dateConfig } from 'modules/app/constants';
import { changeCurrency } from 'modules/financial/utils';
import { secToMin } from 'modules/call/utils';

import { CustomerData } from '../interfaces';
import { getAllCustomers } from '../hooks/crud';

export const CustomerList = () => {
  const { t } = useTranslation('common');
  const { show } = useMessages();
  const { toDollar } = changeCurrency();

  const [tableState, onChangeTableState] = useTableState({
    orderBy: [{ direction: 'desc', field: 'created_at' }],
  });

  const {
    data: customers,
    isError,
    isLoading,
    error,
  } = useQuery<CustomerData[]>(['allCustomers'], () => getAllCustomers());

  useEffect(() => {
    if (isError && error) {
      show({ message: `${t('general.messages.failed')}. ${error}` });
    }
  }, [error, isError, show, t]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'business_name',
        headerName: `${t('customer.list.companyName')}`,
        valueGetter: ({ value }: GridValueGetterParams) => value || '-',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'created_at',
        headerName: `${t('customer.list.joined')}`,
        valueFormatter: ({ value }) => (value ? dayjs(value).format(dateConfig.format.date) : '-'),
        flex: 1,
        minWidth: 110,
      },
      {
        field: 'admin_name',
        headerName: `${t('customer.list.adminName')}`,
        valueGetter: ({ value }: GridValueGetterParams) => value || '-',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'admin_phone',
        headerName: `${t('customer.list.adminPhone')}`,
        valueGetter: ({ value }: GridValueGetterParams) => value || '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'account_number',
        headerName: `${t('customer.list.achAccount')}`,
        valueGetter: ({ value }: GridValueGetterParams) => value || '-',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'routing_number',
        headerName: `${t('customer.list.achRouting')}`,
        valueGetter: ({ value }: GridValueGetterParams) => value || '-',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'stats.total_calls',
        headerName: `${t('customer.list.callsTotal')}`,
        valueGetter: params => params.row.stats.total_calls || '-',
        flex: 1,
        minWidth: 100,
      },
      {
        field: 'stats.monthly_calls',
        headerName: `${t('customer.list.callsMonth')}`,
        valueGetter: params => params.row.stats.monthly_calls || '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'stats.total_fees',
        headerName: `${t('customer.list.feesTotal')}`,
        valueGetter: params =>
          params.row.stats.total_fees ? `$${toDollar(params.row.stats.total_fees)}` : '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'stats.monthly_fees',
        headerName: `${t('customer.list.feesMonth')}`,
        valueGetter: params =>
          params.row.stats.monthly_fees ? `$${toDollar(params.row.stats.monthly_fees)}` : '-',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'stats.monthly_avg_call_duration',
        headerName: `${t('customer.list.avgCallDuration')}`,
        valueGetter: params =>
          params.row.stats.monthly_avg_call_duration
            ? `${secToMin(params.row.stats.monthly_avg_call_duration)} mins`
            : '-',
        flex: 1,
        minWidth: 150,
      },
    ],
    [t, toDollar],
  );

  return (
    <>
      <Loader isLoading={isLoading} />

      <Table
        loading={isLoading}
        columns={columns}
        rows={customers || []}
        rowCount={customers ? customers.length : 0}
        state={tableState}
        onChangeState={onChangeTableState}
      />
    </>
  );
};
