import { ReactNode } from 'react';
import { Container, Box, AppBar, Toolbar, styled } from '@mui/material';

import logoImage from 'modules/app/image/logo.svg';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  top: 0,
  zIndex: theme.zIndex.appBar,
}));

const ToolbarStyled = styled(Toolbar)({
  justifyContent: 'space-between',
});

const ContainerStyled = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  paddingTop: theme.spacing(5),
}));

const MainStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minWidth: 0,
  paddingBottom: theme.spacing(5),
}));

export interface LightLayoutProps {
  children: ReactNode;
  position?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky';
}

export const LightLayout = ({ children, position = 'relative' }: LightLayoutProps) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100%">
      <AppBarStyled color="default" position={position}>
        <Container maxWidth="lg">
          <ToolbarStyled disableGutters>
            <Box display="flex" alignItems="center">
              <Box width={170} component="img" src={logoImage} alt="logo" />
            </Box>
          </ToolbarStyled>
        </Container>
      </AppBarStyled>

      <ContainerStyled maxWidth="md">
        <MainStyled component="main">{children}</MainStyled>
      </ContainerStyled>
    </Box>
  );
};
