import { useContext } from 'react';

import { AuthContext } from '../components/AuthContext';
import { AuthTypeEnum } from '../constants';

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const { token, setToken, logout, user } = context;

  const isSuperAdmin = !!user && user.role === AuthTypeEnum.Admin;
  const isCompanyAdmin = !!user && user.role === AuthTypeEnum.CompanyAdmin;
  const isAllAdmin = isSuperAdmin || isCompanyAdmin;

  const isTechnician = !!user && user.role === AuthTypeEnum.Technician;

  const isUserEmptyProfile = !!user && user.profile === null;

  return {
    token,
    setToken,
    logout,
    user,
    isSignedIn: !!token,
    isSuperAdmin,
    isCompanyAdmin,
    isAllAdmin,
    isTechnician,
    isUserEmptyProfile,
  };
};
