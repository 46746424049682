import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Button, Grid } from '@mui/material';

import { Phone, Text } from 'modules/ui/forms';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { changeCurrency } from 'modules/financial/utils';
import { onModalClose } from 'modules/ui/modal/components/Modal';
import { isTwoDigitsAfterDecimal } from 'modules/ui/forms/validation/isTwoDigitsAfterDecimal';

import { createTechnician, getOneTechnician, updateTechnician } from '../hooks/crud';
import { TechnicianData } from '../interfaces';

interface TechnicianProps {
  userId?: number;
  onModalClose?: onModalClose;
}

export const TechnicianForm = ({ userId, onModalClose }: TechnicianProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');
  const { show } = useMessages();
  const { toDollar, toCent } = changeCurrency();

  const schema = yup.object().shape({
    full_name: yup.string().required(t('general.validation.required')),
    base_call_price: yup
      .number()
      .typeError(t('general.validation.number'))
      .min(1, t('general.validation.minValue', { min: 1 }))
      .test(
        'isTwoDigitsAfterDecimal',
        t('general.validation.digitsAfterDecimal'),
        isTwoDigitsAfterDecimal,
      )
      .required(t('general.validation.required')),
    phone_number: yup.string().required(t('general.validation.required')),
  });

  const { data: technician } = useQuery(['technician', userId], () => getOneTechnician(userId), {
    enabled: !!userId,
    retry: false,
  });

  const { mutate: createMutate } = useMutation(createTechnician, {
    onSuccess: () => {
      queryClient.invalidateQueries(['allTechnician']);

      reset();
      !!onModalClose && onModalClose('submitClick');

      show({ message: t('general.messages.success'), severity: 'success' });
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const { mutate: updateMutate } = useMutation(updateTechnician, {
    onSuccess: () => {
      queryClient.invalidateQueries(['allTechnician']);

      !!onModalClose && onModalClose('submitClick');
      show({ message: t('general.messages.success'), severity: 'success' });
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const { control, handleSubmit, reset } = useForm<TechnicianData>({
    defaultValues: {
      full_name: '',
      base_call_price: undefined,
      phone_number: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (technician) {
      reset({
        full_name: technician.full_name,
        base_call_price: toDollar(technician.base_call_price),
        phone_number: technician.phone_number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technician, reset]);

  const handleFormSubmit = useCallback(
    (data: TechnicianData) => {
      const formatData = {
        ...data,
        base_call_price: toCent(data.base_call_price),
      };

      if (userId) {
        updateMutate({ id: userId, ...formatData });
      } else {
        createMutate(formatData);
      }
    },
    [createMutate, toCent, updateMutate, userId],
  );

  return (
    <Box py={1}>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Text
              label={t('profile.general.field.fullName.label')}
              name="full_name"
              control={control}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Text
              label={t('profile.general.field.pricePerCall.label')}
              name="base_call_price"
              type="number"
              control={control}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Phone
              label={t('profile.general.field.phoneNumber.label')}
              name="phone_number"
              control={control}
              required
            />
          </Grid>
        </Grid>

        <Box mt={3}>
          <Button type="submit">{t('general.button.save')}</Button>
        </Box>
      </form>
    </Box>
  );
};
