import { Route, Switch, Redirect } from 'react-router-dom';

import { useAuth } from 'modules/auth/hooks/useAuth';
import { Loader } from 'modules/ui/loader';
import { MainLayout } from 'modules/layouts';

import { DashboardPage } from './Dashboard';
import { ProfilePage } from './Profile';
import { TechnicianPage } from './Technician';
import { FinancialPage } from './Financial';
import { CustomerPage } from './Customer';
import { TransactionPage } from './Transaction';

export const Private = () => {
  const { user, isUserEmptyProfile, isCompanyAdmin, isSuperAdmin } = useAuth();

  return user ? (
    <MainLayout>
      {isSuperAdmin ? (
        <Switch>
          <Route exact path="/customer" component={CustomerPage} />
          <Route exact path="/transaction" component={TransactionPage} />
          <Route exact path="/financial" component={FinancialPage} />
          <Route exact path="/my-profile" component={ProfilePage} />
          <Redirect to={isUserEmptyProfile ? '/my-profile' : '/customer'} />
        </Switch>
      ) : isCompanyAdmin ? (
        <Switch>
          <Route exact path="/dashboard" component={DashboardPage} />
          <Route exact path="/technician" component={TechnicianPage} />
          <Route exact path="/financial" component={FinancialPage} />
          <Route exact path="/my-profile" component={ProfilePage} />
          <Redirect to={isUserEmptyProfile ? '/my-profile' : '/dashboard'} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/dashboard" component={DashboardPage} />
          <Route exact path="/my-profile" component={ProfilePage} />
          <Redirect to={isUserEmptyProfile ? '/my-profile' : '/dashboard'} />
        </Switch>
      )}
    </MainLayout>
  ) : (
    <Loader isLoading />
  );
};
