import { forwardRef, ReactElement, Ref } from 'react';
import { IconButton, Dialog, DialogProps, DialogTitle, DialogContent, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
  ) => {
    return <Slide direction="down" timeout={500} ref={ref} {...props} />;
  },
);

export type onModalClose = (
  reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick' | 'submitClick',
) => void;

export interface ModalProps extends Omit<DialogProps, 'onClose' | 'onSubmit'> {
  onClose: onModalClose;
}

export const Modal = ({
  open,
  maxWidth = 'md',
  fullWidth = true,
  fullScreen,
  scroll = 'body',
  onClose,
  children,
  title,
}: ModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose('backdropClick')}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      scroll={scroll}
      fullWidth={fullWidth}
      TransitionComponent={Transition}>
      <IconButton
        onClick={() => onClose('closeButtonClick')}
        sx={{
          fontSize: 20,
          position: 'absolute',
          right: 5,
          top: 5,
        }}>
        <CloseIcon fontSize="inherit" />
      </IconButton>

      {title && (
        <DialogTitle variant="h2" component="h3">
          {title}
        </DialogTitle>
      )}

      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
