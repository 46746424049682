import { useEffect, useState } from 'react';
import { useLocation, useHistory, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Box, Button, Paper, Typography } from '@mui/material';

import { Loader } from 'modules/ui/loader';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { verifyEmail } from 'modules/auth/hooks/crud';

export const VerifyEmailPage = () => {
  const { t } = useTranslation('common');
  const { show } = useMessages();
  const history = useHistory();
  const { search } = useLocation();
  const emailToken = new URLSearchParams(search).get('token');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const { isLoading, isError, isSuccess, error } = useQuery(
    ['verifyEmail'],
    () => verifyEmail(emailToken),
    {
      enabled: !!emailToken && !showSuccess,
      retry: false,
    },
  );

  useEffect(() => {
    if (!emailToken) {
      history.push('/sign-in');
    }
    if (isError && error) {
      show({ message: `${t('general.messages.failed')}. ${error}` });
    }

    //TODO: Remove !error after api remove redirect 302
    if (isSuccess || !error) {
      show({ message: t('general.messages.success'), severity: 'success' });
      setShowSuccess(true);
    }
  }, [error, history, isError, isSuccess, show, t, emailToken]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
        <Typography variant="h2" mb={2} textAlign="center">
          {t('auth.emailVerification.title')}
        </Typography>
        <Typography paragraph>
          {showSuccess
            ? t('auth.emailVerification.success')
            : t('auth.emailVerification.description')}
        </Typography>

        {showSuccess && (
          <Box mt={3}>
            <Button component={RouterLink} to="/sign-in">
              {t('auth.signIn.toSignIn')}
            </Button>
          </Box>
        )}
      </Paper>
    </>
  );
};
