import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { changeCurrency } from 'modules/financial/utils';
import { secToMin } from 'modules/call/utils';

import { InfoCardItem } from './InfoCardItem';
import { StatData } from '../interfaces';
import { usePercentage } from '../hooks/usePercentage';

interface InfoCardListProps {
  stats: StatData;
}

export const InfoCardList = ({ stats }: InfoCardListProps) => {
  const { t } = useTranslation('common');
  const { toDollar } = changeCurrency();

  return (
    <Grid container spacing={{ xs: 2, lg: 4 }}>
      <Grid item xs={12} sm={6} md={3}>
        <InfoCardItem
          title={t('dashboard.info.title1')}
          value={stats.calls.value}
          percent={usePercentage(stats.calls)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <InfoCardItem
          title={t('dashboard.info.title2')}
          value={stats.fees.value ? `$${toDollar(stats.fees.value)}` : null}
          percent={usePercentage(stats.fees)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <InfoCardItem
          title={t('dashboard.info.title3')}
          value={
            stats.average_call_duration.value
              ? `${secToMin(stats.average_call_duration.value)} mins`
              : null
          }
          percent={usePercentage(stats.average_call_duration)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <InfoCardItem
          title={t('dashboard.info.title4')}
          value={stats.average_fees.value ? `$${toDollar(stats.average_fees.value)}` : null}
          percent={usePercentage(stats.average_fees)}
        />
      </Grid>
    </Grid>
  );
};
