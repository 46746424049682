import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Box, Grid, Paper, Typography } from '@mui/material';

import { Phone, Text } from 'modules/ui/forms';
import { isPhoneNumber } from 'modules/ui/forms/validation/isPhoneNumber';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { changeCurrency } from 'modules/financial/utils';
import { isTwoDigitsAfterDecimal } from 'modules/ui/forms/validation/isTwoDigitsAfterDecimal';

import { updateUser } from '../hooks/crud';
import { ProfileData } from '../interfaces';

export const ProfileForm = () => {
  const { t } = useTranslation('common');
  const { user, isAllAdmin, isTechnician } = useAuth();
  const { toDollar, toCent } = changeCurrency();
  const queryClient = useQueryClient();
  const { show } = useMessages();

  const schema = useMemo(
    () =>
      yup.object({
        ...(isAllAdmin
          ? {
              company: yup.object().shape({
                address: yup.string().required(t('general.validation.required')),
                business_name: yup.string().required(t('general.validation.required')),
                account_number: yup.string().required(t('general.validation.required')),
                routing_number: yup.string().required(t('general.validation.required')),
              }),
            }
          : {}),
        profile: yup.object().shape({
          base_call_price: yup
            .number()
            .typeError(t('general.validation.number'))
            .min(1, t('general.validation.minValue', { min: 1 }))
            .test(
              'isTwoDigitsAfterDecimal',
              t('general.validation.digitsAfterDecimal'),
              isTwoDigitsAfterDecimal,
            )
            .required(t('general.validation.required')),
          full_name: yup.string().required(t('general.validation.required')),
          phone_number: yup
            .string()
            .required(t('general.validation.required'))
            .test('isValidPhone', t('general.validation.phone'), isPhoneNumber),
          services_provided: yup.string().required(t('general.validation.required')),
        }),
      }),
    [isAllAdmin, t],
  );

  const { handleSubmit, control } = useForm<ProfileData>({
    defaultValues: {
      company: {
        address: user?.company?.address ?? '',
        business_name: user?.company?.business_name ?? '',

        ...(isAllAdmin
          ? {
              account_number: user?.company?.account_number ?? '',
              routing_number: user?.company?.routing_number ?? '',
            }
          : {}),
      },
      profile: {
        base_call_price: user?.profile?.base_call_price
          ? toDollar(user?.profile?.base_call_price)
          : undefined,
        full_name: user?.profile?.full_name ?? '',
        phone_number: user?.profile?.phone_number ?? '',
        services_provided: user?.profile?.services_provided ?? '',
      },
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const { mutate } = useMutation(updateUser, {
    onSuccess: () => {
      show({ message: t('general.messages.success'), severity: 'success' });

      queryClient.invalidateQueries(['getAuthUser']);
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const handleFormSubmit = useCallback(
    (data: ProfileData) => {
      const formatData = {
        ...data,
        company: {
          ...data.company,
          ...(isTechnician
            ? {
                account_number: '',
                routing_number: '',
              }
            : {}),
        },
        profile: {
          ...data.profile,
          base_call_price: toCent(data.profile.base_call_price),
        },
      };

      mutate(formatData);
    },
    [isTechnician, mutate, toCent],
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Paper variant="outlined" sx={{ p: 3 }}>
        <Typography variant="h2" gutterBottom>
          {t('profile.general.title')}
        </Typography>

        <Grid container rowSpacing={3} columnSpacing={4}>
          <Grid item xs={12} sm={6}>
            <Text
              label={t('profile.general.field.businessName.label')}
              name="company.business_name"
              control={control}
              disabled={isTechnician}
              required={isAllAdmin}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Text
              label={t('profile.general.field.companyAddress.label')}
              name="company.address"
              control={control}
              disabled={isTechnician}
              required={isAllAdmin}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Text
              label={t('profile.general.field.fullName.label')}
              name="profile.full_name"
              control={control}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Phone
              label={t('profile.general.field.phoneNumber.label')}
              name="profile.phone_number"
              control={control}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Text
              label={t('profile.general.field.servicesProvided.label')}
              name="profile.services_provided"
              control={control}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Text
              label={t('profile.general.field.pricePerCall.label')}
              name="profile.base_call_price"
              type="number"
              control={control}
              required
            />
          </Grid>
        </Grid>
      </Paper>

      {isAllAdmin && (
        <Paper variant="outlined" sx={{ p: 3, mt: 4 }}>
          <Typography variant="h2" gutterBottom>
            {t('profile.banking.title')}
          </Typography>

          <Grid container rowSpacing={3} columnSpacing={4}>
            <Grid item xs={12} sm={6}>
              <Text
                label={t('profile.banking.field.accountNumber.label')}
                name="company.account_number"
                control={control}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Text
                label={t('profile.banking.field.routingNumber.label')}
                name="company.routing_number"
                control={control}
                required
              />
            </Grid>
          </Grid>
        </Paper>
      )}
      <Box mt={3}>
        <Button type="submit">{t('general.button.save')}</Button>
      </Box>
    </form>
  );
};
