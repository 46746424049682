import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { Modal, ModalProps } from 'modules/ui/modal';
import { Alert } from 'modules/ui/alert';

import { CallLinksToken } from '../interfaces';

interface Props extends ModalProps {
  callTokens: CallLinksToken;
}

export const CallLinksModal = ({ open, onClose, callTokens }: Props) => {
  const { t } = useTranslation('common');

  const location = useMemo(() => window.location.origin, []);

  return (
    <Modal open={open} onClose={onClose} title={t('call.links.title')}>
      <Box mt={1}>
        <Typography variant="h4" mb={1}>
          {t('call.links.technicianLink')}:
        </Typography>
        <Alert
          message={`${location}/room/${callTokens.technician_token}`}
          severity="info"
          icon={<></>}
        />
      </Box>

      <Box mt={3}>
        <Typography variant="h4" mb={1}>
          {t('call.links.customerLink')}:
        </Typography>
        <Alert
          message={`${location}/call/${callTokens.client_token}`}
          severity="warning"
          icon={<></>}
        />
      </Box>
    </Modal>
  );
};
