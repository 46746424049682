import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Paper, Stack, Typography } from '@mui/material';

import { PasswordResetForm } from 'modules/auth/components/admin/PasswordResetForm';

export const PasswordResetPage = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
        <Typography variant="h2" mb={2} textAlign="center">
          {t('auth.passwordReset.title')}
        </Typography>
        <PasswordResetForm />
      </Paper>
      <Stack flexDirection="row" justifyContent="space-between">
        <Link underline="always" color="text.secondary" component={RouterLink} to="/sign-in">
          {t('auth.signIn.toSignIn')}
        </Link>
      </Stack>
    </>
  );
};
