import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Stack, Box, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  Link as LinkIcon,
  Payment as PaymentIcon,
  DatasetLinked as DatasetLinkedIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { Table, useTableState } from 'modules/ui/table';
import { Loader } from 'modules/ui/loader';
import { Label } from 'modules/ui/label';
import { useModal } from 'modules/ui/modal';
import { dateConfig } from 'modules/app/constants';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { PaymentStatusEnum, SessionStatusEnum } from 'modules/financial/interfaces';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { changeCurrency } from 'modules/financial/utils';

import { CallLinksToken, CallData, CallStatusEnum, PeriodCallsEnum } from '../interfaces';
import { CALL_STATUS_COLOR } from '../constants';
import { getAllCalls, callCheckout, callRegenerate } from '../hooks/crud';
import { CallLinksModal } from './CallLinksModal';

export const CallList = () => {
  const { t } = useTranslation('common');
  const { isAllAdmin } = useAuth();
  const { show } = useMessages();
  const { toDollar } = changeCurrency();
  const [periodCalls, setPeriodCalls] = useState(PeriodCallsEnum.LastWeek);
  const [isChargeStatusLoading, setChargeStatusLoading] = useState(false);
  const [isPeriodLoading, setPeriodLoading] = useState(false);
  const [currentRowId, setCurrentRowId] = useState(0);
  const [callTokens, setCallTokens] = useState<CallLinksToken>();
  const { isOpen, close, showModal } = useModal();

  const [tableState, onChangeTableState] = useTableState({
    orderBy: [{ direction: 'desc', field: 'created_at' }],
  });

  const {
    data: calls,
    isError,
    isSuccess,
    isLoading,
    error,
    refetch,
  } = useQuery<CallData[]>(['allCalls'], () => getAllCalls(periodCalls), {
    refetchInterval: 10000,
  });

  useEffect(() => {
    if (!!periodCalls) {
      refetch();
    }

    if (isSuccess) {
      setPeriodLoading(false);
    }

    if (isError && error) {
      show({ message: `${t('general.messages.failed')}. ${error}` });
    }
  }, [calls, error, isError, isSuccess, periodCalls, refetch, show, t]);

  const { mutate: chargeMutate, isLoading: isLoadingCharge } = useMutation(callCheckout, {
    onSuccess: () => {
      setChargeStatusLoading(true);
      show({ message: t('general.messages.success'), severity: 'success' });
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const { mutate: regenerateMutate, isLoading: isLoadingRegenerate } = useMutation(callRegenerate, {
    onSuccess: () => {
      show({ message: t('general.messages.success'), severity: 'success' });
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const handleCharge = useCallback(
    (id: number) => () => {
      chargeMutate(id);
      setCurrentRowId(id);
    },
    [chargeMutate],
  );

  const handleRegenerate = useCallback(
    (id: number) => () => {
      regenerateMutate(id);
      setCurrentRowId(id);
    },
    [regenerateMutate],
  );

  const handleOpenLinksModal = useCallback(
    (links: CallLinksToken) => {
      showModal();
      setCallTokens(links);
    },
    [showModal],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'client_name',
        headerName: `${t('call.list.customerName')}`,
        valueGetter: ({ value }) => value || '-',
        flex: 1,
        minWidth: 165,
      },
      {
        field: 'client_phone_number',
        headerName: `${t('call.list.customerPhone')}`,
        valueGetter: ({ value }) => value || '-',
        flex: 1,
        minWidth: 150,
      },
      ...(isAllAdmin
        ? [
            {
              field: 'technician',
              headerName: `${t('call.list.technicianName')}`,
              valueGetter: ({ value }: GridValueGetterParams) => value.full_name || '-',
              flex: 1,
              minWidth: 165,
            },
          ]
        : []),
      {
        field: 'token',
        headerName: `${t('call.list.links')}`,
        headerAlign: 'left',
        align: 'left',
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <Button
            onClick={() => handleOpenLinksModal(params.row.token)}
            variant="text"
            startIcon={<LinkIcon />}>
            {t('call.button.links')}
          </Button>
        ),
        width: 100,
        minWidth: 100,
      },
      {
        field: 'created_at',
        headerName: `${t('call.list.callCreate')}`,
        valueFormatter: ({ value }) =>
          value ? dayjs(value).format(dateConfig.format.dateTime) : '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'call_start_time',
        headerName: `${t('call.list.callStart')}`,
        valueFormatter: ({ value }) =>
          value ? dayjs(value).format(dateConfig.format.dateTime) : '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'call_end_time',
        headerName: `${t('call.list.callEnd')}`,
        valueFormatter: ({ value }) =>
          value ? dayjs(value).format(dateConfig.format.dateTime) : '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'call_max_participants',
        headerName: `${t('call.list.peopleJoined')}`,
        valueGetter: ({ value }) => value || '-',
        flex: 1,
        minWidth: 120,
      },
      {
        field: 'price',
        headerName: `${t('call.list.fees')}`,
        valueGetter: ({ value }) => `$${toDollar(value)}`,
        flex: 1,
        minWidth: 80,
      },
      {
        field: 'status',
        headerName: `${t('call.list.callStatus')}`,
        renderCell: ({ value }) =>
          value ? <Label text={t(`call.status.${value}`)} color={CALL_STATUS_COLOR[value]} /> : '-',
        flex: 1,
        minWidth: 120,
      },
      ...(isAllAdmin
        ? [
            {
              field: 'actions',
              headerName: '',
              sortable: false,
              disableColumnMenu: true,
              renderCell: (params: GridRenderCellParams) => {
                const callId = params.row.id;
                const isCurrentRowId = currentRowId === callId;

                const isDisabled =
                  !params.row.session ||
                  params.row.session?.payment_status === PaymentStatusEnum.Paid ||
                  params.row.session?.status !== SessionStatusEnum.Complete;

                const isChargeDisabled = params.row.status !== CallStatusEnum.Finished;

                return (
                  <Stack gap={0.25} useFlexGap alignItems="flex-end" flex={1}>
                    {params.row.session?.payment_status === PaymentStatusEnum.Paid ? (
                      <Box color="success.main">
                        {t('financial.payment.status.paymentCollected')}
                      </Box>
                    ) : (
                      <>
                        <LoadingButton
                          loading={
                            (isLoadingCharge && isCurrentRowId) ||
                            (isChargeStatusLoading && isCurrentRowId)
                          }
                          loadingPosition="start"
                          onClick={handleCharge(callId)}
                          disabled={isDisabled || isChargeDisabled}
                          variant="text"
                          startIcon={<PaymentIcon />}>
                          {t('call.button.charge')}
                        </LoadingButton>
                        <LoadingButton
                          loading={isLoadingRegenerate && isCurrentRowId}
                          loadingPosition="start"
                          onClick={handleRegenerate(callId)}
                          disabled={isDisabled}
                          variant="text"
                          startIcon={<DatasetLinkedIcon />}>
                          {t('call.button.regenerateLinks')}
                        </LoadingButton>
                      </>
                    )}
                  </Stack>
                );
              },
              width: 175,
              minWidth: 175,
            },
          ]
        : []),
    ],
    [
      currentRowId,
      handleCharge,
      handleOpenLinksModal,
      handleRegenerate,
      isAllAdmin,
      isChargeStatusLoading,
      isLoadingCharge,
      isLoadingRegenerate,
      t,
      toDollar,
    ],
  );

  const handlePeriodCallsChange = useCallback((_: any, value: PeriodCallsEnum) => {
    if (value) {
      setPeriodLoading(true);
      setPeriodCalls(value);
    }
  }, []);

  return (
    <>
      <Loader isLoading={isLoading || isPeriodLoading} />

      <Box display="flex" justifyContent="flex-end" mb={1}>
        <ToggleButtonGroup
          color="primary"
          value={periodCalls}
          exclusive
          onChange={handlePeriodCallsChange}>
          <ToggleButton value={PeriodCallsEnum.Today}>{t('call.button.today')}</ToggleButton>
          <ToggleButton value={PeriodCallsEnum.LastWeek}>{t('call.button.week')}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Table
        loading={isLoading || isPeriodLoading}
        columns={columns}
        rows={calls || []}
        rowCount={calls ? calls.length : 0}
        state={tableState}
        onChangeState={onChangeTableState}
      />

      {callTokens && <CallLinksModal open={isOpen} onClose={close} callTokens={callTokens} />}
    </>
  );
};
