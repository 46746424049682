import { useTranslation } from 'react-i18next';

import { Modal, ModalProps } from 'modules/ui/modal';

import { TechnicianForm } from './TechnicianForm';

interface Props extends ModalProps {
  userId?: number;
}

export const TechnicianFormModal = ({ open, onClose, userId, title }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Modal open={open} onClose={onClose} title={title || t('technician.create.title')}>
      <TechnicianForm userId={userId} onModalClose={onClose} />
    </Modal>
  );
};
