import { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paper, Typography, Link, Stack } from '@mui/material';

import { Tabs } from 'modules/ui/tabs';
import { SignInForm } from 'modules/auth/components/admin/SignInForm';
import { SignInTechnician } from 'modules/auth/components/technician/SignIn';
import { AuthTypeEnum } from 'modules/auth/constants';

export const SignInPage = () => {
  const { t } = useTranslation('common');
  const [tab, setTabValue] = useState<string>(AuthTypeEnum.Admin);

  const tabs = useMemo(
    () => [
      {
        id: AuthTypeEnum.Admin,
        label: t('auth.type.admin'),
      },
      {
        id: AuthTypeEnum.Technician,
        label: t('auth.type.technician'),
      },
    ],
    [t],
  );

  const handleChangeTab = useCallback((_: any, newTabValue: string) => {
    setTabValue(newTabValue);
  }, []);

  return (
    <>
      <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
        <Typography variant="h2" mb={2} textAlign="center">
          {t('auth.signIn.title')}
        </Typography>
        <Tabs tabs={tabs} onChange={handleChangeTab} value={tab} />
        {tab === AuthTypeEnum.Admin && <SignInForm />}
        {tab === AuthTypeEnum.Technician && <SignInTechnician />}
      </Paper>
      {tab === AuthTypeEnum.Admin && (
        <Stack flexDirection="row" justifyContent="space-between">
          <Link underline="always" color="text.secondary" component={RouterLink} to="/sign-up">
            {t('auth.signIn.signUpLink')}
          </Link>

          <Link
            underline="always"
            color="text.secondary"
            component={RouterLink}
            to="/password-reset">
            {t('auth.signIn.forgotPasswordLink')}
          </Link>
        </Stack>
      )}
    </>
  );
};
