import { useMemo } from 'react';

import { StatItemInterface } from '../interfaces';

export const usePercentage = ({ previous_month_value, value }: StatItemInterface) => {
  return useMemo(() => {
    if (!!value && !!previous_month_value) {
      return parseFloat((((value - previous_month_value) / previous_month_value) * 100).toFixed(2));
    } else {
      return null;
    }
  }, [previous_month_value, value]);
};
