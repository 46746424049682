import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Modal, ModalProps } from 'modules/ui/modal';

export interface ConfirmationModalProps extends ModalProps {
  description: ReactNode;
  onSubmit: (close: () => void) => void;
  cancelButtonText?: ReactNode;
  submitButtonText?: ReactNode;
  isLoading?: boolean;
}

export const ConfirmationModal = ({
  open,
  onClose,
  onSubmit,
  title,
  description,
  cancelButtonText,
  submitButtonText,
  isLoading,
  ...props
}: ConfirmationModalProps) => {
  const { t } = useTranslation('common');

  const handleClose = useCallback(() => {
    onClose('closeButtonClick');
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit(() => onClose('submitClick'));
  }, [onSubmit, onClose]);

  return (
    <Modal open={open} onClose={onClose} maxWidth="sm" title={title} {...props}>
      {description}
      <Stack direction="row" useFlexGap gap={1} justifyContent="space-between" mt={3}>
        <Button variant="text" color="primary" onClick={handleClose}>
          {cancelButtonText || t('general.button.cancel')}
        </Button>
        <LoadingButton loading={isLoading} onClick={handleSubmit} variant="contained">
          {submitButtonText || t('general.button.yes')}
        </LoadingButton>
      </Stack>
    </Modal>
  );
};
