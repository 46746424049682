import { Tab as MuiTab, Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';
import { Tab } from '../interfaces';

interface Props extends MuiTabsProps {
  tabs: Tab[];
}

export const Tabs = ({ tabs, value, onChange, variant = 'scrollable' }: Props) => {
  return (
    <MuiTabs
      sx={{
        mb: 2.5,
        borderBottom: theme => `1px solid ${theme.palette.grey[200]}`,
      }}
      value={value}
      onChange={onChange}
      variant={variant}>
      {tabs.map(({ id, label, disabled = false }) => (
        <MuiTab label={label} key={id} disabled={disabled} value={id} />
      ))}
    </MuiTabs>
  );
};
