import { apiClient } from 'modules/app/configs/axios';

import { ProfileData } from '../interfaces';

const endpoint = '/user';

export const getUser = async () => {
  const response = await apiClient.get(`${endpoint}/me`);
  return response.data;
};

export const updateUser = async (data: ProfileData) => {
  try {
    const response = await apiClient.put(`${endpoint}/me`, data);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};
