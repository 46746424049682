import { useState, ReactNode, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'modules/app/configs/axios';
import { getUser } from 'modules/profile/hooks/crud';

import { Token } from '../types';
import { User } from '../interfaces';
import { AUTH_TOKEN } from '../constants';
import { AuthContext, AuthContextValueProps } from './AuthContext';

interface AuthContextProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthContextProps) => {
  const [token, setToken] = useState<string | null>(() => {
    return localStorage.getItem(AUTH_TOKEN);
  });
  const [user, setUser] = useState<User | null>(null);

  const saveToken = (newToken: Token) => {
    setToken(newToken);

    if (newToken) {
      localStorage.setItem(AUTH_TOKEN, newToken);
    } else {
      localStorage.removeItem(AUTH_TOKEN);
    }
  };

  const logout = () => {
    saveToken(null);

    delete apiClient.defaults.headers['x-api-token'];
    localStorage.removeItem(AUTH_TOKEN);
  };

  const { isSuccess, error, data } = useQuery(['getAuthUser', token], getUser, {
    retry: false,
    enabled: Boolean(token),
    refetchInterval: 60000,
  });

  useEffect(() => {
    if (isSuccess || !error) {
      setUser(data);
    }
  }, [data, error, isSuccess]);

  const contextValue: AuthContextValueProps = {
    token,
    user,
    setToken: saveToken,
    logout,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
