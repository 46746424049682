import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { FinancialList } from 'modules/financial/components/FinancialList';

export const FinancialPage = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Typography variant="h1" mb={2}>
        {t('financial.title')}
      </Typography>
      <FinancialList />
    </>
  );
};
