import { apiClient } from 'modules/app/configs/axios';

const endpoint = '/calls';

export const getAllTransaction = async () => {
  try {
    const response = await apiClient.get(`${endpoint}`);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};
