import { TechnicianData } from 'modules/technician/interfaces';
import { SessionInterface } from 'modules/financial/interfaces';

export enum CallStatusEnum {
  Pending = 'pending',
  Finished = 'finished',
  Failed = 'failed',
  Regenerated = 'regenerated',
}

export enum PeriodCallsEnum {
  Today = 'today',
  LastWeek = 'lastWeek',
}

export interface CreateCallData {
  id?: number;
  user_id?: number;
  client_name: string;
  phone_number: string;
}

export interface CallLinksToken {
  client_token: string;
  technician_token: string;
}

export interface CallData {
  id?: number;
  client_name?: string;
  client_phone_number: string;
  technician: Omit<TechnicianData, 'base_call_price'>;
  token: CallLinksToken;
  call_start_time: string;
  call_end_time: string;
  created_at: string;
  call_max_participants?: string | number;
  session: SessionInterface | null;
  price: string | number;
  status?: CallStatusEnum;
}

export interface CallRoomInterface {
  meeting_token: string;
  room_name: string;
}
