import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { TransactionList } from 'modules/transaction/components/TransactionList';

export const TransactionPage = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Typography variant="h1" mb={2}>
        {t('transaction.title')}
      </Typography>

      <TransactionList />
    </>
  );
};
