import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Alert, AlertProps } from 'modules/ui/alert';

interface Props extends AlertProps {}

export const useMessages = () => {
  const handleShow = useCallback(({ message, severity = 'error' }: Props) => {
    const alertElement = (
      <div>
        <Alert message={message} severity={severity} />
      </div>
    );

    toast(alertElement, {
      position: 'top-right',
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      theme: 'light',
    });
  }, []);

  return { show: handleShow };
};
