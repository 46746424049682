import { useTranslation } from 'react-i18next';
import { Paper, styled, Typography } from '@mui/material';

const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  height: '100%',
}));

interface InfoCardItemProps {
  title: string;
  value: string | number | null;
  percent: number | null;
}

export const InfoCardItem = ({ title, value, percent }: InfoCardItemProps) => {
  const { t } = useTranslation('common');

  return (
    <PaperStyled variant="outlined">
      <Typography variant="h2">{title}</Typography>
      <Typography variant="caption" component="div" color="text.secondary">
        {t('dashboard.info.monthToDate')}
      </Typography>
      <Typography color="primary" my={1} fontSize={46} fontWeight={700}>
        {value || '-'}
      </Typography>
      <Typography color="text.secondary">
        {!!percent && `${percent}% ${t('dashboard.info.preMonth')}`}
      </Typography>
    </PaperStyled>
  );
};
