import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Button, Grid } from '@mui/material';

import { Phone, Text } from 'modules/ui/forms';
import { onModalClose } from 'modules/ui/modal/components/Modal';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { getAllTechnicians } from 'modules/technician/hooks/crud';
import { TechnicianData } from 'modules/technician/interfaces';
import { useAuth } from 'modules/auth/hooks/useAuth';

import { createCall } from '../hooks/crud';
import { CreateCallData } from '../interfaces';

interface CreateCallProps {
  onModalClose?: onModalClose;
}

export const CreateCall = ({ onModalClose }: CreateCallProps) => {
  const { t } = useTranslation('common');
  const { isAllAdmin, user } = useAuth();
  const queryClient = useQueryClient();
  const { show } = useMessages();

  const {
    data: technicians = [],
    isError,
    error,
  } = useQuery<TechnicianData[]>(['allTechnician'], () => getAllTechnicians(), {
    enabled: isAllAdmin,
  });

  useEffect(() => {
    if (isError && error) {
      show({ message: `${t('general.messages.failed')}. ${error}` });
    }
  }, [error, isError, show, t]);

  const { mutate: createMutate } = useMutation(createCall, {
    onSuccess: () => {
      queryClient.invalidateQueries(['allCalls']);
      show({ message: t('call.create.message.successCreateRoom'), severity: 'success' });

      reset();
      !!onModalClose && onModalClose('submitClick');
    },
    onError: ({ message }) => {
      show({ message: `${t('call.create.message.errorCreateRoom')}. ${message}` });
    },
  });

  const technicianOptions = useMemo(
    () =>
      technicians.map((item: any) => ({
        value: item.id,
        name: item.full_name,
      })),
    [technicians],
  );

  const schema = yup.object({
    ...(isAllAdmin ? { user_id: yup.number().required(t('general.validation.required')) } : {}),
    client_name: yup.string().required(t('general.validation.required')),
    phone_number: yup.string().required(t('general.validation.required')),
  });

  const { control, handleSubmit, reset } = useForm<CreateCallData>({
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = useCallback(
    (data: CreateCallData) => {
      if (isAllAdmin) {
        createMutate(data);
      } else {
        createMutate({ user_id: user?.id, ...data });
      }
    },
    [createMutate, isAllAdmin, user?.id],
  );

  return (
    <Box py={1}>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Grid container spacing={3}>
          {isAllAdmin && (
            <Grid item xs={12}>
              <Text
                label={t('call.create.field.technician.label')}
                name="user_id"
                control={control}
                select
                options={technicianOptions}
                required
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Text
              label={t('call.create.field.customerName.label')}
              name="client_name"
              control={control}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Phone
              label={t('call.create.field.customerPhone.label')}
              name="phone_number"
              control={control}
              required
            />
          </Grid>
        </Grid>

        <Box mt={3}>
          <Button type="submit" fullWidth>
            {t('call.button.createCall')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
