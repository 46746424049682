import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import Daily, { DailyCall } from '@daily-co/daily-js';

import { useMessages } from 'modules/messages/hooks/useMessages';

import { CALL_OPTIONS, CALL_DOMAIN } from '../constants';

const CallFrameStyled = styled(Box, { shouldForwardProp: prop => prop !== 'height' })<{
  height: number;
}>(({ height, theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  flex: 1,
  minHeight: height,
  maxHeight: 1000,
  borderRadius: theme.shape.borderRadius,

  '& > iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
  },
}));

interface DailyCallPrebuiltProps {
  roomName: string;
  meetingToken: string;
}

export const DailyCallPrebuilt = ({ roomName, meetingToken }: DailyCallPrebuiltProps) => {
  const { t } = useTranslation('common');
  const { show } = useMessages();
  const videoRef = useRef<HTMLDivElement | null>(null);
  const [callFrame, setCallFrame] = useState<DailyCall | null>(null);
  const [height, setHeight] = useState<number>(720);

  useEffect(() => {
    if (!videoRef || !videoRef?.current || callFrame) return;

    const newCallFrame = Daily.createFrame(videoRef.current, CALL_OPTIONS);

    newCallFrame
      .join({ url: `${CALL_DOMAIN}/${roomName}`, token: meetingToken })
      .then(() => {
        setCallFrame(newCallFrame);
        setHeight((videoRef?.current?.clientWidth || 500) * 0.75);
      })
      .catch(({ errorMsg }) => {
        show({ message: `${t('general.messages.failed')}. ${errorMsg}` });
      });
  }, [callFrame, show, t, roomName, meetingToken]);

  return <CallFrameStyled ref={videoRef} height={height} title="Call" />;
};
