import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Paper, styled } from '@mui/material';

import { changeCurrency } from 'modules/financial/utils';

import { chartColor, chartOptions } from '../constants';
import { GraphInterface } from '../interfaces';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const PaperStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 400,
  padding: theme.spacing(2, 1, 1),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4, 2, 3),
  },
}));

interface StatisticChartProps {
  stats: GraphInterface;
}

export const StatisticChart = ({ stats }: StatisticChartProps) => {
  const { t } = useTranslation('common');
  const { toDollar } = changeCurrency();

  const labels = useMemo(() => {
    return stats.labels.map(date => dayjs(date).format('MMM DD'));
  }, [stats]);

  const dataFees = useMemo(() => {
    return stats.datasets.fees.map(item => (item === null ? 0 : toDollar(item)));
  }, [stats, toDollar]);

  const dataCalls = useMemo(() => {
    return stats.datasets.calls.map(item => (item === null ? 0 : item));
  }, [stats]);

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: t('dashboard.chart.fees'),
          data: dataFees,
          borderColor: chartColor[1],
          backgroundColor: chartColor[1],
          yAxisID: 'y',
        },
        {
          label: t('dashboard.chart.calls'),
          data: dataCalls,
          borderColor: chartColor[0],
          backgroundColor: chartColor[0],
          yAxisID: 'y1',
        },
      ],
    }),
    [labels, t, dataFees, dataCalls],
  );

  return (
    <PaperStyled variant="outlined">
      <Line options={chartOptions} data={data} />
    </PaperStyled>
  );
};
