import { apiClient } from 'modules/app/configs/axios';

const endpoint = '/admin/customers';

export const getAllCustomers = async () => {
  try {
    const response = await apiClient.get(`${endpoint}`);

    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};
