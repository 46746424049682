import { apiClient } from 'modules/app/configs/axios';

import { SentDataInterface } from '../interfaces';

const endpoint = '/financial_reports';

export const getAllFinancial = async () => {
  try {
    const response = await apiClient.get(`${endpoint}`);
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};

export const financialSent = async ({ reportId, isSent }: SentDataInterface) => {
  try {
    const response = await apiClient.put(`${endpoint}/${reportId}`, {
      is_sent: isSent,
    });
    return response.data;
  } catch (error: any) {
    throw error.data;
  }
};
