import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Table, useTableState } from 'modules/ui/table';
import { Loader } from 'modules/ui/loader';
import { dateConfig } from 'modules/app/constants';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { changeCurrency } from 'modules/financial/utils';
import { secToMin } from 'modules/call/utils';

import { getAllTransaction } from '../hooks/crud';
import { TransactionData } from '../interfaces';

export const TransactionList = () => {
  const { t } = useTranslation('common');
  const { show } = useMessages();
  const { toDollar } = changeCurrency();

  const [tableState, onChangeTableState] = useTableState({
    orderBy: [{ direction: 'asc', field: 'technician.company_name' }],
  });

  const {
    data: calls,
    isError,
    isLoading,
    error,
  } = useQuery<TransactionData[]>(['allTransaction'], getAllTransaction);

  useEffect(() => {
    if (isError && error) {
      show({ message: `${t('general.messages.failed')}. ${error}` });
    }
  }, [error, isError, show, t]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'company_name',
        headerName: `${t('transaction.list.companyName')}`,
        flex: 1,
        minWidth: 165,
      },
      {
        field: 'technician.full_name',
        headerName: `${t('transaction.list.technicianName')}`,
        valueGetter: params => params.row.technician.full_name || '-',
        flex: 1,
        minWidth: 165,
      },
      {
        field: 'technicianPhone',
        headerName: `${t('transaction.list.technicianPhone')}`,
        valueGetter: params => params.row.technician.phone_number || '-',
        flex: 1,
        minWidth: 165,
      },
      {
        field: 'client_name',
        headerName: `${t('transaction.list.customerName')}`,
        valueGetter: ({ value }) => value || '-',
        flex: 1,
        minWidth: 165,
      },
      {
        field: 'client_phone_number',
        headerName: `${t('transaction.list.customerPhone')}`,
        valueGetter: ({ value }) => value || '-',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'call_start_time',
        headerName: `${t('transaction.list.callStart')}`,
        valueFormatter: ({ value }) =>
          value ? dayjs(value).format(dateConfig.format.dateTime) : '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'call_end_time',
        headerName: `${t('transaction.list.callEnd')}`,
        valueFormatter: ({ value }) =>
          value ? dayjs(value).format(dateConfig.format.dateTime) : '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'call_duration',
        headerName: `${t('transaction.list.callDuration')}`,
        renderCell: (params: GridRenderCellParams) =>
          params.row.call_start_time && params.row.call_end_time
            ? `${secToMin(
                dayjs(params.row.call_end_time).diff(dayjs(params.row.call_start_time), 'second'),
              )} mins`
            : '-',
        flex: 1,
        minWidth: 130,
      },
      {
        field: 'price',
        headerName: `${t('transaction.list.fees')}`,
        valueGetter: ({ value }) => `$${toDollar(value)}`,
        flex: 1,
        minWidth: 80,
      },
    ],
    [t, toDollar],
  );

  return (
    <>
      <Loader isLoading={isLoading} />

      <Table
        loading={isLoading}
        columns={columns}
        rows={calls || []}
        rowCount={calls ? calls.length : 0}
        state={tableState}
        onChangeState={onChangeTableState}
      />
    </>
  );
};
