import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Box } from '@mui/material';

import { Text } from 'modules/ui/forms';
import { useMessages } from 'modules/messages/hooks/useMessages';

import { AuthData } from '../../interfaces';
import { resetEmail } from '../../hooks/crud';

export const PasswordResetForm = () => {
  const { t } = useTranslation('common');
  const { show } = useMessages();

  const schema = useMemo(
    () =>
      yup.object({
        email: yup.string().email().required(t('general.validation.required')),
      }),
    [t],
  );

  const { handleSubmit, control, reset } = useForm<Omit<AuthData, 'password'>>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const { mutate } = useMutation(resetEmail, {
    onSuccess: () => {
      reset();

      show({ message: t('general.messages.auth.resetPasswordEmailSuccess'), severity: 'success' });
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const handleFormSubmit = useCallback(
    (data: Omit<AuthData, 'password'>) => mutate(data),
    [mutate],
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Text
        label={t('general.field.email.label')}
        name="email"
        type="email"
        control={control}
        required
      />

      <Box mt={3}>
        <Button type="submit" fullWidth>
          {t('auth.button.send')}
        </Button>
      </Box>
    </form>
  );
};
