import dayjs from 'dayjs';

export const chartOptions = {
  spanGaps: true,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
  },
  scales: {
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
      beginAtZero: true,
      title: {
        display: true,
        text: 'Fees, $',
      },
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      beginAtZero: true,
      title: {
        display: true,
        text: 'Calls',
      },
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
  },
};

export const chartColor = [
  '#EB5757',
  '#00BC9D',
  '#566F9E',
  '#F89235',
  '#DE89AB',
  '#008953',
  '#007A80',
];

export const chartLabels = () => {
  const labels = [];
  for (let i = 29; i >= 0; i--) {
    labels.push(dayjs().subtract(i, 'day').format('MMM DD'));
  }
  return labels;
};
