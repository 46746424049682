import { useTranslation } from 'react-i18next';
import { Paper, Box, Typography, Button, styled, alpha } from '@mui/material';

import { LightLayout } from 'modules/layouts/components/LightLayout';

const PaperStyled = styled(Paper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.error.main, 0.075),
  margin: 'auto',
  padding: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(7),
  },
}));

export const CardAuthorizeErrorPage = () => {
  const { t } = useTranslation('common');

  return (
    <LightLayout>
      <PaperStyled>
        <Typography variant="h1" textAlign="center" mb={4}>
          {t('system.errors.cardAuthorize.title')}
        </Typography>
        <Typography variant="h3" mb={3}>
          {t('system.errors.cardAuthorize.message')}
        </Typography>
        <Typography gutterBottom color="text.secondary" mb={2}>
          {t('system.errors.cardAuthorize.message2')}
        </Typography>
        {/*<Box mt={4} textAlign="center">*/}
        {/*  <Button onClick={() => {}}>{t('system.button.tryAgain')}</Button>*/}
        {/*</Box>*/}
      </PaperStyled>
    </LightLayout>
  );
};
