import { createContext } from 'react';

import { Token } from 'modules/auth/types';
import { User } from 'modules/auth/interfaces';

export interface AuthContextValueProps {
  token: Token;
  setToken: (token: string | null) => void;
  user: User | null;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextValueProps | undefined>(undefined);
