import { usePrevious } from 'react-use';
import { DataGrid, DataGridProps, GridRowHeightReturnValue } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';

import { TableState } from '../types';

const pageSizeOptions: DataGridProps['pageSizeOptions'] = [10, 30, 50];

const slots: DataGridProps['slots'] = {
  loadingOverlay: LinearProgress,
  columnUnsortedIcon: ({ sortingOrder, ...rest }) => <UnfoldMoreRoundedIcon {...rest} />,
};

const initialState: DataGridProps['initialState'] = {
  pagination: { paginationModel: { page: 0 } },
};

const getRowHeight = () => 'auto' as GridRowHeightReturnValue;

export interface TableProps extends DataGridProps {
  state: TableState;
  onChangeState: (stata: Partial<TableState>) => void;
}

export const Table = ({
  state,
  onChangeState,
  rowCount,
  columns,
  sortingMode = 'client',
  paginationMode = 'client',
  ...props
}: TableProps) => {
  const previousRowCount = usePrevious(rowCount);

  return (
    <DataGrid
      {...props}
      autoHeight
      pagination
      columnHeaderHeight={50}
      rowHeight={60}
      rowSpacingType="border"
      disableRowSelectionOnClick
      sortingMode={sortingMode}
      paginationMode={paginationMode}
      columns={columns}
      initialState={initialState}
      slots={slots}
      pageSizeOptions={pageSizeOptions}
      rowCount={rowCount || previousRowCount || 0}
      getRowHeight={getRowHeight}
      paginationModel={{
        page: state.page - 1,
        pageSize: state.pageSize,
      }}
      onPaginationModelChange={model =>
        onChangeState({ page: model.page + 1, pageSize: model.pageSize })
      }
      sortModel={state.orderBy?.map(({ direction, field }) => ({ field, sort: direction }))}
      onSortModelChange={orderBy =>
        onChangeState({ orderBy: orderBy.map(({ sort, field }) => ({ field, direction: sort })) })
      }
    />
  );
};
