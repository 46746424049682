import { useCallback, useState } from 'react';

import { TableState } from '../types';

export const useTableState = <Filter = Record<any, any>>(
  initialState: Partial<TableState> = {},
): [TableState<Filter>, (newState: Partial<TableState<Filter>>) => void] => {
  const [state, setState] = useState<TableState>({
    page: 1,
    pageSize: 10,
    orderBy: [],
    ...initialState,
  });

  const handleChangeState = useCallback((newState: Partial<TableState<Filter>>) => {
    setState(oldState => ({ ...oldState, ...newState }));
  }, []);

  return [state, handleChangeState];
};
