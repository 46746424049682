import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { CustomerList } from 'modules/customer/components/CustomerList';

export const CustomerPage = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Typography variant="h1" mb={2}>
        {t('customer.title')}
      </Typography>
      <CustomerList />
    </>
  );
};
