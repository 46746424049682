import { createTheme } from '@mui/material';
import { createBreakpoints } from '@mui/system';

//use module augmentation to extend the default theme structure
import type {} from '@mui/x-data-grid/themeAugmentation';

const breakpoints = createBreakpoints({});

const palette = {
  primary: {
    light: '#337786',
    main: '#015668',
    dark: '#003C48',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#0ab3ba',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#EB5757',
  },
  success: {
    main: '#22bb33',
  },
  info: {
    main: '#0A84DD',
  },
  warning: {
    main: '#ff8f00',
  },
  grey: {
    50: '#F8F8F8',
    100: '#F0F1F2',
    200: '#D6D8DF',
    500: '#808080',
  },
  text: {
    primary: '#012c35',
    secondary: '#7E8288',
    disabled: '#A4ADBA',
  },
};

const fontFamily = '"Roboto", sans-serif';

export const theme = createTheme({
  palette,
  typography: {
    fontFamily,
    fontSize: 14,
    fontWeightBold: 600,

    h1: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: 1.4,

      [breakpoints.up('sm')]: {
        fontSize: 32,
      },
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,

      [breakpoints.up('sm')]: {
        fontSize: 24,
      },
    },
    h3: {
      fontSize: 18,
      fontWeight: 600,

      [breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1.4,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.4,
      textTransform: 'none',
    },
  },
  breakpoints,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
          WebkitTextSizeAdjust: '100%',
        },
        body: {
          backgroundColor: palette.grey[100],
          '&, & > #root': {
            height: '100%',
          },
          '& *': {
            scrollbarColor: `${palette.primary.main} ${palette.grey[200]}` /* thumb and track color for Firefox */,
            scrollbarWidth: 'thin',

            '&::-webkit-scrollbar': {
              width: 6,
              height: 6,
              backgroundColor: palette.grey[200],
              borderRadius: 50,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: palette.grey[200],
              borderRadius: 50,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: palette.primary.main,
              borderRadius: 50,
            },
          },
          '& .Toastify__toast': {
            padding: 0,
            minHeight: 0,
            borderRadius: 8,

            '&-body': {
              padding: 0,
              margin: 0,
            },
            '& .Toastify__close-button': {
              position: 'absolute',
              top: 5,
              right: 5,
            },
          },
          '& a': {
            color: palette.primary.main,
            textDecoration: 'none',

            '&:hover': {
              textDecoration: 'underline',
            },
          },
          '& img, & svg': {
            maxWidth: '100%',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: '0.7em',
        },
        paragraph: {
          marginBottom: '20px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: `1px solid ${palette.grey[200]}`,
        },
        colorDefault: {
          backgroundColor: '#FFF',
          color: palette.text.primary,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: 60,

          '@media (min-width: 0px) and (orientation: landscape)': {
            minHeight: 60,
          },
          [breakpoints.up('sm')]: {
            minHeight: 60,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily,
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      defaultProps: { variant: 'contained', color: 'primary' },
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          fontWeight: 400,
          minWidth: 110,
        },
        contained: {
          padding: '10px 20px',
        },
        text: {
          minWidth: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: { elevation: 0 },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderColor: palette.grey[200],
          },
        },
      ],
      styleOverrides: {},
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderColor: palette.grey[200],
          borderRadius: 8,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',

          [breakpoints.down('sm')]: {
            margin: 15,
            width: 'calc(100% - 30px)',

            '&.MuiDialog-paperScrollBody': {
              maxWidth: 'calc(100% - 30px)',
            },
            '&.MuiDialog-paperScrollBody.MuiDialog-paperFullScreen': {
              width: '100%',
              maxWidth: '100%',
            },
          },
        },
        paperWidthSm: {
          [breakpoints.up('sm')]: {
            maxWidth: 400,
          },
        },
        paperWidthMd: {
          [breakpoints.up('md')]: {
            maxWidth: 600,
          },
        },
        paperWidthLg: {
          [breakpoints.up('lg')]: {
            maxWidth: 800,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '16px 24px 0',

          [breakpoints.up('sm')]: {
            padding: '30px 30px 0',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 24,

          [breakpoints.up('sm')]: {
            padding: 30,
          },

          '.MuiDialogTitle-root + &': {
            paddingTop: '12px',
          },
        },
      },
    },

    //Data Grid (Tables)
    MuiDataGrid: {
      defaultProps: {
        disableColumnSelector: true,
        disableRowSelectionOnClick: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
          fontSize: 14,
          borderRadius: 8,
          borderColor: palette.grey[200],

          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
            flex: 1,
            padding: '4px 8px',

            '&:focus, &:focus-within': {
              outline: '0 !important',
            },
            '&:first-of-type': {
              paddingLeft: 16,

              [breakpoints.up('sm')]: {
                paddingLeft: 20,
              },
            },
            '&:last-of-type, &:nth-last-of-type(2)': {
              paddingRight: 16,

              [breakpoints.up('sm')]: {
                paddingRight: 20,
              },
            },
          },
        },
        columnHeaders: {
          backgroundColor: palette.grey[50],
          borderColor: palette.grey[200],
          borderRadius: '8px 8px 0 0',
        },
        row: {
          minHeight: '60px !important',

          '&:hover, &.Mui-hovered': {
            backgroundColor: 'rgba(0,0,0,0.015)',
          },
        },
        cell: {
          borderColor: palette.grey[200],
        },
        columnHeaderTitle: {
          fontWeight: 600,
          color: palette.text.secondary,
        },
        columnSeparator: {
          display: 'none !important',
        },
        iconButtonContainer: {
          visibility: 'visible',
          width: 'auto',
          marginLeft: 3,

          '.MuiDataGrid-sortIcon': {
            opacity: '1 !important',
            fill: 'currentColor',
          },
          '.MuiIconButton-root': {
            width: 24,
            height: 24,

            '.MuiDataGrid-columnHeader--sort &': {},
            '.MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader--sorted &': {
              color: palette.primary.main,
            },
          },
        },
        footerContainer: {
          backgroundColor: palette.grey[50],
          borderColor: palette.grey[200],
          borderRadius: '0 0 8px 8px',
          paddingLeft: 8,
          paddingRight: 8,

          [breakpoints.up('sm')]: {
            paddingLeft: 24,
            paddingRight: 24,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
          fontSize: 13,
        },
        toolbar: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          minHeight: 0,
          paddingLeft: 10,
          paddingRight: 0,
        },
        selectRoot: {
          minWidth: 55,
          marginLeft: 10,
          marginRight: 16,

          [breakpoints.up('sm')]: {
            marginLeft: 15,
            marginRight: 30,
          },
        },
        select: {
          border: `1px solid ${palette.grey[200]}`,
          paddingTop: 6,
          paddingBottom: 6,
          color: palette.text.secondary,

          '&, &:focus': {
            backgroundColor: '#FFF !important',
            borderRadius: 8,
          },
        },
        selectIcon: {
          color: palette.text.secondary,
          fontSize: 20,
        },
        actions: {
          color: palette.text.secondary,
          marginLeft: 10,

          [breakpoints.up('sm')]: {
            marginLeft: 20,
          },
        },
      },
    },
  },
});
