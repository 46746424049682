import axios, { AxiosError } from 'axios';

import { AUTH_TOKEN } from 'modules/auth/constants';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json',
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
});

// Request:
apiClient.interceptors.request.use(async config => {
  if (!config.headers.Authorization) {
    const storedToken = localStorage.getItem(AUTH_TOKEN);

    if (storedToken) {
      config.headers.Authorization = `Bearer ${storedToken}`;
    }
  }

  return config;
});

// Response:
apiClient.interceptors.response.use(
  response => response,
  async (error: ResponseError) => {
    if (error.response) {
      const { status } = error.response;

      // Unauthorized (401) - Logout
      if (status === 401) {
        delete apiClient.defaults.headers['x-api-token'];
        localStorage.removeItem(AUTH_TOKEN);

        window.location.assign('/sign-in');
      }

      // Forbidden (403) - Permission denied
      if (status === 403) {
        window.location.assign('/403');
      }
    }

    return Promise.reject({
      data: error?.response?.data,
      statusCode: error?.response?.status,
    });
  },
);

export interface ResponseError extends AxiosError {
  data: any;
  statusCode: number;
}
