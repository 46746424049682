import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Typography, Box } from '@mui/material';

import { Loader } from 'modules/ui/loader';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { CallList } from 'modules/call/components/CallList';
import { StatisticChart } from 'modules/dashboard/components/StatisticChart';
import { InfoCardList } from 'modules/dashboard/components/InfoCardList';
import { getCAStats } from 'modules/dashboard/hooks/crud';
import { StatData } from 'modules/dashboard/interfaces';

export const DashboardPage = () => {
  const { show } = useMessages();
  const { t } = useTranslation('common');

  const {
    data: stats,
    isError,
    isLoading: isStatsLoading,
    error,
  } = useQuery<StatData>(['getCAStats'], getCAStats);

  useEffect(() => {
    if (isError && error) {
      show({ message: `${t('general.messages.failed')}. ${error}` });
    }
  }, [error, isError, show, t]);

  return (
    <>
      <Loader isLoading={isStatsLoading} />

      <Typography variant="h1" mb={2}>
        {t('dashboard.title')}
      </Typography>

      {stats && (
        <Box mb={4}>
          <InfoCardList stats={stats} />
        </Box>
      )}

      {stats && (
        <Box mb={4}>
          <StatisticChart stats={stats.graph} />
        </Box>
      )}

      <CallList />
    </>
  );
};
