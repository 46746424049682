import { useCallback, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Container,
  Link,
  Box,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircle as AccountCircleIcon,
  VideoChatRounded as VideoChatRoundedIcon,
} from '@mui/icons-material';

import { Drawer } from 'modules/ui/drawer';
import { Menu } from 'modules/ui/menu';
import { useIsMobile } from 'modules/app/hooks/useIsMobile';
import { useAuth } from 'modules/auth/hooks/useAuth';
import logoImage from 'modules/app/image/logo.svg';
import { useModal } from 'modules/ui/modal';
import { CreateCallModal } from 'modules/call/components/CreateCallModal';

export interface Props {
  children: ReactNode;
}

export const MainLayout = ({ children }: Props) => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { zIndex } = useTheme();
  const isMobile = useIsMobile();
  const { user, isAllAdmin, isCompanyAdmin, isSuperAdmin, logout, isUserEmptyProfile } = useAuth();
  const { isOpen, close, showModal } = useModal();

  const [mainMenuOpen, setMainMenuOpen] = useState(false);

  const handleMainMenuToggle = useCallback(() => {
    if (isMobile) {
      setMainMenuOpen(!mainMenuOpen);
    }
  }, [isMobile, mainMenuOpen]);

  const startsWith = (urls: string[]) =>
    urls.some((url: string) => location?.pathname?.startsWith(url));

  const menu = [
    {
      name: t('dashboard.title'),
      to: '/dashboard',
      active: startsWith(['/dashboard']),
      onClick: handleMainMenuToggle,
      disabled: isUserEmptyProfile,
      show: !isSuperAdmin,
    },
    {
      name: t('technician.title'),
      to: '/technician',
      active: startsWith(['/technician']),
      onClick: handleMainMenuToggle,
      disabled: isUserEmptyProfile,
      show: isCompanyAdmin,
    },
    {
      name: t('customer.title'),
      to: '/customer',
      active: startsWith(['/customer']),
      onClick: handleMainMenuToggle,
      disabled: isUserEmptyProfile,
      show: isSuperAdmin,
    },
    {
      name: t('transaction.title'),
      to: '/transaction',
      active: startsWith(['/transaction']),
      onClick: handleMainMenuToggle,
      disabled: isUserEmptyProfile,
      show: isSuperAdmin,
    },
    {
      name: t('financial.title'),
      to: '/financial',
      active: startsWith(['/financial']),
      onClick: handleMainMenuToggle,
      disabled: isUserEmptyProfile,
      show: isAllAdmin,
    },
    {
      name: t('profile.title'),
      to: '/my-profile',
      active: startsWith(['/my-profile']),
      onClick: handleMainMenuToggle,
      show: isMobile,
    },
  ];

  const createCallButton = !isSuperAdmin ? (
    <Button
      onClick={showModal}
      disabled={isUserEmptyProfile}
      sx={{
        justifyContent: 'flex-start',
        mr: { md: 4 },
        mt: { xs: 3, md: 0 },
      }}
      color="secondary"
      startIcon={<VideoChatRoundedIcon />}
      fullWidth={isMobile}>
      {t('call.button.createCall')}
    </Button>
  ) : (
    ''
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}>
      {isMobile && (
        <Drawer
          open={mainMenuOpen}
          handleClose={handleMainMenuToggle}
          header={
            <Box display="flex" alignItems="center">
              <IconButton
                sx={{
                  color: 'text.primary',
                  padding: 0,
                  mr: 0.5,
                }}
                onClick={handleMainMenuToggle}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h4" component="h3">
                {t('general.menu.title')}
              </Typography>
            </Box>
          }>
          <Menu menu={menu} variant="vertical" />

          {createCallButton}

          <Button
            onClick={logout}
            sx={{
              justifyContent: 'space-between',
              mt: 6,
            }}
            fullWidth
            endIcon={<ExitToAppIcon fontSize="inherit" />}>
            {t('general.button.logout')}
          </Button>
        </Drawer>
      )}

      <AppBar
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: zIndex.appBar,
          bgcolor: '#FFF',
        }}
        color="default"
        position="static">
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              justifyContent: 'space-between',
            }}
            disableGutters>
            {isMobile && (
              <IconButton
                sx={{
                  color: 'text.primary',
                  fontSize: 24,
                  padding: '6px',
                  marginLeft: '-6px',
                  marginRight: '5px',
                }}
                onClick={handleMainMenuToggle}>
                <MenuIcon fontSize="inherit" />
              </IconButton>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Link
                sx={{
                  display: 'inline-block',
                  lineHeight: 1,
                  verticalAlign: 'top',
                  '&:hover, &:focus': {
                    opacity: 0.9,
                    textDecoration: 'none',
                  },
                }}
                component={RouterLink}
                to="/">
                <Box width={170} component="img" src={logoImage} alt="logo" />
              </Link>
              {!isMobile && (
                <Menu
                  sx={{
                    '& .nav-menu-item': {
                      height: 60,
                    },
                    marginLeft: {
                      sm: '30px',
                      md: '40px',
                      lg: '50px',
                    },
                  }}
                  menu={menu}
                />
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: 36,
              }}>
              {!isMobile && (
                <>
                  {createCallButton}
                  <Link
                    underline="none"
                    color="text.secondary"
                    component={RouterLink}
                    to="/my-profile"
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      color: 'primary.main',
                      fontWeight: 500,
                      lineHeight: 1,

                      '&:hover': {
                        textDecoration: 'none',
                        opacity: 0.8,
                      },
                    }}>
                    <AccountCircleIcon color="primary" sx={{ mr: 0.5, fontSize: 32 }} />
                    {user?.profile?.full_name}
                  </Link>
                  <IconButton
                    onClick={logout}
                    sx={{
                      color: 'text.secondary',
                      fontSize: 18,
                      padding: '6px',
                      marginRight: '-6px',
                      ml: 3,
                    }}>
                    <ExitToAppIcon fontSize="inherit" />
                  </IconButton>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          pt: 5,
        }}>
        <Box
          component="main"
          sx={{
            minWidth: 0,
            flexGrow: 1,
            pb: 5,
          }}>
          {!!user ? children : <></>}
        </Box>
      </Container>

      <CreateCallModal open={isOpen} onClose={close} />
    </Box>
  );
};
