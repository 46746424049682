import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Switch } from '@mui/material';

import { Table, useTableState } from 'modules/ui/table';
import { Loader } from 'modules/ui/loader';
import { Label } from 'modules/ui/label';
import { dateConfig } from 'modules/app/constants';
import { useMessages } from 'modules/messages/hooks/useMessages';
import { useAuth } from 'modules/auth/hooks/useAuth';

import { FinancialData, FeeStatusEnum, SentDataInterface } from '../interfaces';
import { PAYMENT_STATUS_COLOR } from '../constants';
import { financialSent, getAllFinancial } from '../hooks/crud';
import { changeCurrency } from '../utils';

export const FinancialList = () => {
  const { t } = useTranslation('common');
  const { show } = useMessages();
  const { toDollar } = changeCurrency();
  const { isSuperAdmin } = useAuth();

  const [tableState, onChangeTableState] = useTableState({
    orderBy: [{ direction: 'desc', field: 'date' }],
  });

  const {
    data: financials,
    isError,
    isLoading,
    refetch,
    error,
  } = useQuery<FinancialData[]>(['allFinancial'], () => getAllFinancial());

  useEffect(() => {
    if (isError && error) {
      show({ message: `${t('general.messages.failed')}. ${error}` });
    }
  }, [error, isError, show, t]);

  const { mutate: sentMutate, isLoading: isLoadingSent } = useMutation(financialSent, {
    onSuccess: () => {
      show({ message: t('general.messages.success'), severity: 'success' });

      refetch();
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.failed')}. ${message}` });
    },
  });

  const handleSent = useCallback(
    ({ reportId, isSent }: SentDataInterface) =>
      () => {
        sentMutate({ reportId, isSent });
      },
    [sentMutate],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'date',
        headerName: `${t('financial.list.date')}`,
        valueFormatter: ({ value }) => (value ? dayjs(value).format(dateConfig.format.date) : '-'),
        flex: 1,
        minWidth: 200,
      },
      ...(isSuperAdmin
        ? [
            {
              field: 'company_name',
              headerName: `${t('financial.list.businessName')}`,
              valueGetter: ({ value }: GridValueGetterParams) => value || '-',
              flex: 1,
              minWidth: 200,
            },
          ]
        : []),
      {
        field: 'call_count',
        headerName: `${t('financial.list.callsCompleted')}`,
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'call_fees',
        headerName: `${t('financial.list.feesCharged')}`,
        valueGetter: ({ value }) => (value ? `$${toDollar(value)}` : '-'),
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'service_fees',
        headerName: `${t('financial.list.serviceCallFee')}`,
        valueGetter: ({ value }) => (value ? `$${toDollar(value)}` : '-'),
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'net_proceeds',
        headerName: `${t('financial.list.netProceeds')}`,
        valueGetter: ({ value }) => (value ? `$${toDollar(value)}` : '-'),
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'status',
        headerName: `${t('financial.list.status')}`,
        renderCell: (params: GridRenderCellParams) => {
          const statusSent = params.row?.is_sent ? FeeStatusEnum.Sent : FeeStatusEnum.Pending;

          return (
            <Label
              text={t(`financial.payment.status.${statusSent}`)}
              color={PAYMENT_STATUS_COLOR[statusSent]}
            />
          );
        },
        flex: 1,
        minWidth: 100,
      },
      ...(isSuperAdmin
        ? [
            {
              field: 'is_sent',
              headerName: `${t('financial.list.sent')}`,
              sortable: false,
              disableColumnMenu: true,
              renderCell: (params: GridRenderCellParams) => {
                const reportId = params.row.id;
                const isSent = params.row.is_sent;

                return (
                  <Switch checked={isSent} onChange={handleSent({ reportId, isSent: !isSent })} />
                );
              },
              flex: 1,
              minWidth: 100,
            },
          ]
        : []),
    ],
    [handleSent, isSuperAdmin, t, toDollar],
  );

  return (
    <>
      <Loader isLoading={isLoading || isLoadingSent} />

      <Table
        loading={isLoading || isLoadingSent}
        columns={columns}
        rows={financials || []}
        rowCount={financials ? financials.length : 0}
        state={tableState}
        onChangeState={onChangeTableState}
      />
    </>
  );
};
