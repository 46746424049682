import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { Button, Box, Stack } from '@mui/material';

import { Text, Password } from 'modules/ui/forms';
import { isPasswordStrong } from 'modules/ui/forms/validation/isPasswordStrong';
import { useMessages } from 'modules/messages/hooks/useMessages';

import { adminRegister } from '../../hooks/crud';
import { AuthData } from '../../interfaces';

export const SignUpForm = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const { show } = useMessages();

  const schema = useMemo(
    () =>
      yup.object({
        email: yup.string().email().required(t('general.validation.required')),
        password: yup
          .string()
          .trim()
          .test('test-pass', t('general.validation.passwordStrong') as string, value =>
            Boolean(value && isPasswordStrong.test(value)),
          )
          .required(t('general.validation.required')),
      }),
    [t],
  );

  const { handleSubmit, control, reset } = useForm<AuthData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { mutate } = useMutation(adminRegister, {
    onSuccess: () => {
      history.push('/sign-in');
      reset();

      show({ message: t('general.messages.auth.registrationSuccess'), severity: 'success' });
    },
    onError: ({ message }) => {
      show({ message: `${t('general.messages.auth.registrationFailed')}: ${message}` });
    },
  });

  const handleFormSubmit = useCallback((data: AuthData) => mutate(data), [mutate]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Stack spacing={3}>
        <Text
          label={t('general.field.email.label')}
          name="email"
          type="email"
          control={control}
          required
        />
        <Password
          label={t('general.field.password.label')}
          name="password"
          type="password"
          control={control}
          required
        />
      </Stack>

      <Box mt={3}>
        <Button type="submit" fullWidth>
          {t('auth.button.signUp')}
        </Button>
      </Box>
    </form>
  );
};
