import { DailyCallOptions } from '@daily-co/daily-js';
import dayjs from 'dayjs';
import { theme as MuiTheme } from 'modules/app/configs/theme';

import { CallStatusEnum } from '../interfaces';

export const CALL_DOMAIN = 'https://servicecall.daily.co';

export const CALL_OPTIONS: DailyCallOptions = {
  theme: {
    colors: {
      accent: MuiTheme.palette.success.main,
      accentText: MuiTheme.palette.common.white,
      background: MuiTheme.palette.primary.main,
      backgroundAccent: MuiTheme.palette.primary.dark,
      baseText: MuiTheme.palette.common.white,
      border: MuiTheme.palette.grey[200],
      mainAreaBg: MuiTheme.palette.primary.dark,
      mainAreaBgAccent: MuiTheme.palette.primary.light,
      mainAreaText: MuiTheme.palette.common.white,
      supportiveText: MuiTheme.palette.grey[200],
    },
  },
  showLeaveButton: true,
  showFullscreenButton: true,
};

export const CALL_STATUS_COLOR = {
  [CallStatusEnum.Pending as string]: 'warning',
  [CallStatusEnum.Finished as string]: 'success',
  [CallStatusEnum.Failed as string]: 'error',
  [CallStatusEnum.Regenerated as string]: 'info',
};

export const TODAY = dayjs().format('YYYY-MM-DD');
export const LAST_WEEK = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
