export const changeCurrency = () => {
  const toDollar = (value: number) => {
    return parseFloat((value / 100).toFixed(2));
  };
  const toCent = (value: number) => {
    return value * 100;
  };

  return { toDollar, toCent };
};
