import { ReactNode } from 'react';

import { Drawer as MuiDrawer, DrawerProps, Box } from '@mui/material';

export interface Props extends DrawerProps {
  handleClose: () => void;
  keepMounted?: boolean;
  hideBackdrop?: boolean;
  header?: ReactNode | boolean;
}

export const Drawer = ({
  children,
  open,
  anchor,
  handleClose,
  keepMounted = false,
  hideBackdrop = false,
  header,
}: Props) => {
  return (
    <MuiDrawer
      sx={{
        '& .MuiDrawer-paper': {
          width: 274,
          boxShadow: 'none',
        },
      }}
      anchor={anchor}
      variant="temporary"
      open={open}
      onClose={handleClose}
      elevation={0}
      ModalProps={{
        keepMounted,
        hideBackdrop,
      }}>
      {header && (
        <Box
          sx={{
            position: 'relative',
            pt: 3,
            px: 3,
            pb: 1,
          }}>
          {header}
        </Box>
      )}
      <Box
        sx={{
          overflowY: 'auto',
          pt: 2,
          px: 3,
          pb: 3,
        }}>
        {children}
      </Box>
    </MuiDrawer>
  );
};
